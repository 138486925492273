import React, { useState, useEffect } from "react";
import style from "./faqsList.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SearchBar from "../../components/search-bar/SearchBar";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaqsList } from "./table/table";
import { Box, Typography } from "@mui/material";
import daynamicpage from "../../api/daynamicpage";
import { getFaqsPages } from "../../store/faqs/faqsAction";
import { Pagination, Stack } from "@mui/material";
import SelectableButton from "../../components/buttons/SelectableButton";



export function FaqsTable() {
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [filter, setFilter] = useState("");
  const [reset, setReset] = useState(false);
  const [staticData, setStatic] = useState([]);
  const [resData, setResData] = useState("");
  const [key, setKey] = useState(0);

  const dealerId = localStorage.getItem("dealerId");
  const dispatch = useDispatch();

  const { data, hasNextPage, totalCount, currentPage, totalpageCount } = useSelector((state) => state?.faqsReducers?.faqsList || []);

  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageSize: 1,
    pageIndex: 1
  });

  const applyFilter = async () => {
    await dispatch(getFaqsPages({ page_slug: resData }))
  };

  const resetFilter = async () => {
    await dispatch(getFaqsPages())
    setFilter("");
    setResData("");
    setKey(prev => prev + 1); // Force re-render
  };



  const handleSearch = (e) => {
    let filterd = data;

    if (searchText) {
      filterd = filterd.filter((item) =>
        item.question.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    setFilterData(filterd);
  };

  useEffect(() => {
    dispatch(getFaqsPages({ page: pagination.pageIndex }))
  }, [dispatch, dealerId, pagination.pageIndex])

  useEffect(() => {
    handleSearch();
  }, [searchText, data]);



  const getAllPages = async () => {
    try {
      const res = await daynamicpage.getAllPages();
      console.log('res =>', res)
      setStatic(res?.data)
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getAllPages();
  }, [])

  const getApi = (id) => {
    const data = staticData.find(item => item?.id == id);
    setResData(data?.page_slug)
  }


  return (
    <div className={style["container"]}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 0",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          Faqs List ({totalCount})
        </Typography>
      </Box>

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <SearchBar
            placeholder={"Search Question"}
            handleChange={(e)=> setSearchText(e.target.value)}
          />
          <SelectableButton
            key={key}
            trailingIcon={R.ic_arrow_down}
            containerStyle={style["selectable-button-container-style"]}
            handleChange={(e) => {
              getApi(e.target.value);
              setFilter(e.target.value);
            }}
            items={staticData.map(item => ({
              label: item.page_name,
              value: item?.id
            }))}
            hint={"All Pages"}
            selectedItems={filter}
          />

          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={" Filter"}
            fontSize={"1rem"}
            onClick={applyFilter}
          />

          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={"Reset"}
            fontSize={"1rem"}
            onClick={() => resetFilter()}
          />

        </div>
        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["add-blogs-button"]}
            leadingIcon={R.ic_add_white}
            name={"Add New Faqs"}
            fontSize={"1rem"}
            linkTo={"/create-faqs"}
            onClick={() => navigate("/create-faqs")}
          />
        </div>
      </div>
      <FaqsList
        value={filterData}
        totalCount={totalCount}
      />

      <Stack
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={"20px"}
      >
        <Pagination
          count={totalpageCount}
          page={pagination.pageIndex}
          onChange={(event, page) => {
            setPagination((prev) => ({
              ...prev,
              pageIndex: page
            }));
          }}
          variant="outlined"
          shape="rounded"
        />
      </Stack>

    </div>
  );
}

