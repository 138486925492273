import React, { useState } from "react";
import style from "./table.module.scss";
import { testimonialsApi } from "../../../api/testimonials";
import { useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { R } from "../../../constants/resources";
import { Pagination, Stack } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomizedDialogs } from "../../../components/tables/DetailTable";
export default function TestimonialList({
  value,
  setValues,
  totalCount,
  setTotalCount,
}) {
  const navigate = useNavigate();
  const [view, setView] = useState(window.innerWidth > 1200 ? "list" : "grid");
  const [open, setOpen] = useState(false);
  const [dialogBox, setDialogBox] = useState({
    id: "",
    titleMessage: "",
    confirmMessage: "",
    handleConfirm: () => { },
  });

  const handleViewChange = () => {
    setView(view === "grid" ? "list" : "grid");
  };
  const GetStatusLabel = ({ status }) => {
    let label;
    switch (status) {
      case "1":
        label = "Published";
        break;
      case "0":
        label = "Archived";
        break;
      case "2":
        label = "Draft";
        break;
      default:
        label = "Unknown";
    }
    return <span>{label}</span>;
  };
  const getDate = (date) => {
    return new Date(date).toLocaleDateString();
  };
  const dealer_id = localStorage.getItem("dealerId");
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, sethasNextpage] = useState();
  const [pagination, setPagination] = useState({
    pageSize: 1,
    pageIndex: 0,
  });
  useEffect(() => {
    const res = async () => {
      try {
        const parmas = {
          page: pagination.pageIndex,
        }
        const res = await testimonialsApi.getTestimonials(parmas);
        setValues(res.data);
        setPageCount(res.totalPageCount);
        setCurrentPage(res.currentPage);
        sethasNextpage(res.hasNexPage);
        setTotalCount(res.totalCount);
      } catch (error) {
        console.log(error);
      }
    };
    res();
  }, [pagination.pageIndex, setTotalCount]);
  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, pageIndex: page }));
    setCurrentPage(page);
  };
  // const onDelete = async (id) => {
  //   try {
  //     const res = await testimonialsApi.dropTestimonials({ id });
  //     console.log("your deleted data is -->", res);
  //     const updatedRes = await testimonialsApi.getTestimonials();
  //     setValues(updatedRes?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const handleDelete = async (id) => {
    try {
      const res = await testimonialsApi.dropTestimonials({ id });
      const updatedRes = await testimonialsApi.getTestimonials();
      setValues(updatedRes?.data);
    } catch (error) {
      console.log("erorr =>", error);
    }
  }


  const onDelete = (id) => {
    setDialogBox({
      id: id,
      titleMessage: "Warning!",
      confirmMessage: "Are you sure you want to delete this Testimonial?",
      handleConfirm: () => handleDelete(id),
    });
    setOpen(true);
  };


  const columns = React.useMemo(
    () => [
      {
        accessorKey: "sno",
        header: "S.No",
        cell: (info) => info.row.index + 1,
      },
      // {
      //   accessorKey: "descriptions",
      //   header: "Descriptions",
      //   cell: (info) => {
      //     const description = info.getValue();
      //     return description.length > 20
      //       ? `${description.slice(0, 20)}...`
      //       : description;
      //   },
      // },
      {
        accessorKey: "customer_name",
        header: "Customer Name",
      },
      {
        accessorKey: "create_at",
        header: "Created Date",
        cell: (info) => getDate(info.getValue()),
      },
      {
        accessorKey: "update_at",
        header: "Updated Date",
        cell: (info) => getDate(info.getValue()),
      },
      {
        accessorKey: "action",
        header: "Actions",
        cell: ({ row }) => <ActionButtons data={row.original} />,
      },
    ],
    []
  );
  const table = useReactTable({
    data: value,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleClose = () => {
    setOpen(false);
  };
  const ActionButtons = ({ data }) => {
    return (
      <Stack direction="row" spacing={1}>
        <Tooltip title="Edit">
          <IconButton onClick={() => onEditClick(data)}>
            <EditIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton onClick={() => onDelete(data.id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };
  const onEditClick = (data) => {
    console.log("edited_id -->", data);
    navigate("/create-testinomial", { state: { data: data } });
  };
  return (
    <>
      <IconButton
        aria-label="toggle view"
        onClick={handleViewChange}
        sx={{
          ml: "auto",
          display: "flex",
          justifyContent: "flex-end",
          display: { xs: "none", md: "block" },
        }}
      >
        {view === "grid" ? <ViewListIcon /> : <ViewModuleIcon />}
      </IconButton>
      <div className={`${style["blog-table-container"]} table-responsive`}>
        <>
          {view === "grid" ? (
            <Grid
              container
              spacing={1}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              {value?.map((car, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <Card
                    sx={{
                      margin: 1,
                      ":hover": {
                        cursor: "pointer",
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <CardContent>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography
                          sx={{ height: "30px", overflow: "hidden" }}
                          variant="body1"
                          component="div"
                        >
                          {car?.customer_name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          <ActionButtons data={car} />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Status
                        </Typography>
                        <Typography variant="body2" color="h6">
                          <GetStatusLabel status={car?.status} />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Created Date
                        </Typography>
                        <Typography variant="body2" color="h6">
                          {car?.create_at
                            ? new Date(car?.create_at).toLocaleDateString()
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Updated Date
                        </Typography>
                        <Typography variant="body2" color="h6">
                          {car?.update_at
                            ? new Date(car?.update_at).toLocaleDateString()
                            : "-"}
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <List>
              <TableContainer
                component={Paper}
                sx={{ width: "99%", margin: "0px 10px 0px 6px" }}
              >
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: "#f5f5f5",
                      "& .MuiTableCell-root": {
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell align="left">S.No</TableCell>
                      <TableCell align="left">Customer Name</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Created Date</TableCell>
                      <TableCell align="left">Updated Date</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {value?.map((car, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        <TableCell sx={{ ml: 2, width: "10%" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell>{car?.customer_name}</TableCell>
                        <TableCell>
                          <GetStatusLabel status={car?.status} />
                        </TableCell>
                        <TableCell>
                          {car?.create_at
                            ? new Date(car?.create_at).toLocaleDateString()
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {car?.update_at
                            ? new Date(car?.update_at).toLocaleDateString()
                            : "-"}
                        </TableCell>
                        <TableCell>
                          <ActionButtons data={car} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </List>
          )}
          <CustomizedDialogs
            open={open}
            handleClose={handleClose}
            titleMessage={dialogBox.titleMessage}
            confirmMessage={dialogBox.confirmMessage}
            handleConfirm={() => {
              dialogBox.handleConfirm();
              handleClose();
            }}
          />
        </>
      </div>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={"20px"}
      >
        <Pagination
          count={pageCount}
          page={pagination.pageIndex}
          onChange={(event, page) => {
            setPagination((prev) => ({
              ...prev,
              pageIndex: page
            }));
          }}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
}
