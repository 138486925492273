import React, { useState, useEffect } from "react";
import style from "../dealers-blogs/style.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SelectableButton from "../../components/buttons/SelectableButton";
import SearchBar from "../../components/search-bar/SearchBar";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { dealerId, R } from "../../constants/resources";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BlogTable from "./table/BlogTable";
import { blogs } from "../../api/blogs";
import { getBuyerList } from "../../store/buyerLead/buyerLeadAction";
import { Box, Typography } from "@mui/material";
import { Pagination, Stack } from "@mui/material";
import { fetchDealerBlogs } from "../../store/blogs/blogsAction";

function DealerBlogs() {

  const { data, totalCount, totalPageCount } = useSelector((state) => state?.blogsReducers?.blogsList || []);

  const [deaalerStatus, setStatus] = useState("");
  const dispatch = useDispatch();
  const [key, setKey] = useState(0);
  const [filterData, setFilterData] = useState();
  const [searchText, setSearchText] = useState("");


  const [pagination, setPagination] = useState({
    pageSize: 1,
    pageIndex: 1
  });

  

  const navigate = useNavigate();

  const handleSearch = () => {
    let filterd = data;

    if (searchText) {
      filterd = filterd.filter((item) =>
        item.author.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    setFilterData(filterd);
  };

  useEffect(() => {
    handleSearch();
  }, [searchText, data]);


  const resetFilter = async () => {
    await dispatch(fetchDealerBlogs())
    setKey(prev => prev + 1);
  }


  const applyFilter = async () => {
    await dispatch(fetchDealerBlogs({ status: deaalerStatus }))
  }

  useEffect(() => {
    dispatch(fetchDealerBlogs({ page: pagination.pageIndex }));
  }, [dispatch, pagination.pageIndex])

  return (
    <div className={style["container"]}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 0",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          Blogs ({totalCount})
        </Typography>
      </Box>

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <div className={style['search-bar']}>
            <SearchBar
              name="author"
              placeholder={"Author"}
              handleChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <SelectableButton
            key={key}
            trailingIcon={R.ic_arrow_down}
            containerStyle={style["selectable-button-container-style"]}
            handleChange={(e) => setStatus(e.target.value)}
            items={[
              { label: "Archived", value: "0" },
              { label: "Published", value: "1" },
              { label: "Drafts", value: "2" },
            ]}
            hint={"All Blogs"}
            selectedItems={deaalerStatus}
          />

          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={" Filter"}
            fontSize={"1rem"}
            onClick={applyFilter}
          />

          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={"Reset"}
            fontSize={"1rem"}
            onClick={() => resetFilter()}
          />
        </div>
        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["add-blogs-button"]}
            leadingIcon={R.ic_add_white}
            name={"Add New Blog"}
            fontSize={"1rem"}
            linkTo={"/create-blogs"}
            onClick={() => navigate("/create-blogs")}
          />
        </div>
      </div>
      <BlogTable
        data={filterData}
        total={totalCount}
      />


      <Stack
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={"20px"}
      >
        <Pagination
          count={totalPageCount}
          page={pagination.pageIndex}
          onChange={(event, page) => {
            setPagination((prev) => ({
              ...prev,
              pageIndex: page
            }));
          }}
          variant="outlined"
          shape="rounded"
        />
      </Stack>


    </div>
  );
}

export default DealerBlogs;
