import React, { useState } from "react";
import { R } from "../../constants/resources";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import style from "./detailTable.module.scss";
import moment from "moment";
// import { saveCsvFileData , updateCsvStatus} from "../../store/lead/leadAction";
import { buyerApi } from "../../api/buyerLeadApi";
import { showToast } from "../../components/toaster/toaster";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
export default function LeadTable({ data = [], check }) {
  // console.log("Hi Janmejay ", data)
  const [loading, setLoading] = useState({});
  const [processedRows, setProcessedRows] = useState({});

  const columns = [
    { accessorKey: "lead_id", header: "Sheet Id" },
    // { accessorKey: "username", header: "Uploaded By" },
    { accessorKey: "file_name", header: "File Name" },
    {
      accessorKey: "path_name",
      header: "View",
      cell: ({ getValue }) => {
        const path = getValue();
        return (
          <a href={path} target="_blank" rel="noopener noreferrer">
            View File
          </a>
        );
      },
    },
    {
      accessorKey: "created_at",
      header: "Created Date",
      cell: ({ getValue }) => moment(getValue()).format("DD-MM-YYYY"),
    },
    { accessorKey: "new_lead", header: "New Lead" },
    { accessorKey: "duplicate_lead", header: "Duplicate Lead" },
    {
      accessorKey: "processed",
      header: "Status",
      cell: ({ getValue }) => {
        const processedValue = getValue();
        return processedValue === 0 ? "Pending" : "Completed";
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: ({ row }) => {
        const { lead_id, processed } = row.original;
        const isLoading = loading[lead_id];
        const isProcessed = processedRows[lead_id];

        return processed === 0 && !isProcessed ? (
          <button
            className={style.processButton}
            onClick={() => handleProcessClick(row.original)}
            disabled={isLoading}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Process"
            )}
          </button>
        ) : null;
      },
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onDelete = async (rowData) => {
    const leadId = rowData.lead_id;

    const isConfirmed = window.confirm(
      "Do you wish to delete this file permanently?"
    );

    if (!isConfirmed) {
      return;
    }

    try {
      const response = await buyerApi.updateCsvStatus(leadId);
      const status = response.status;
      if (status == 200) {
        showToast("success", response.message);
        check();
      }
    } catch (error) {
      console.log("error ", error.message);
      showToast("error ", error.message);
    }
  };

  const handleProcessClick = async (rowData) => {
    const leadId = rowData.lead_id;
    console.log("leadId ", leadId);

    try {
      setLoading((prev) => ({ ...prev, [leadId]: true }));

      // Create the single object to send
      const requestData = {
        leadId: rowData.lead_id,
        csvfile: rowData.path_name,
      };

      // Call the API with the single object
      const response = await buyerApi.saveCsvFileData(requestData);

      if (response?.status == 1) {
        showToast("success", "Data Processed successfully");
        // setProcessedRows((prev) => ({ ...prev, [leadId]: true }));
        check();
      }
    } catch (error) {
      console.error("Error saving CSV file data:", error);
      showToast("error", error.message);
    } finally {
      setLoading((prev) => ({ ...prev, [leadId]: false })); // Reset loading state for this lead
    }
  };

  return (
    <div
      className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
    >
      <table
        className={`${style["table-wrapper"]} table table-border table-hover`}
        cellSpacing={"5px"}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup?.id} className={style["table-header-wrapper"]}>
              {headerGroup?.headers?.map((header) => (
                <th key={header?.id} className={style["table-header"]}>
                  {flexRender(
                    header?.column?.columnDef?.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table?.getRowModel()?.rows?.map((row) => {
            const { lead_id, processed } = row.original; // Extract the lead_id and processed value
            const isLoading = loading[lead_id];
            const isProcessed = processedRows[lead_id];

            return (
              <tr key={row?.id} className={style["table-row"]}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {cell?.column?.columnDef?.accessorKey === "action" ? (
                      processed === 0 && !isProcessed ? (
                        <>
                          <button
                            className={style.processButton}
                            onClick={() => handleProcessClick(row.original)}
                            disabled={isLoading} // Disable button while loading
                          >
                            {isLoading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Process"
                            )}
                          </button>
                          <Tooltip title="Delete">
                            <IconButton
                              size="small"
                              onClick={() => onDelete(row.original)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <span className={style.submitted}>Processed</span>
                      )
                    ) : (
                      flexRender(
                        cell?.column?.columnDef?.cell,
                        cell?.getContext()
                      )
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
