import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
// import PrimaryHeading from "../../../../../components/primary-heading/PrimaryHeading";
import { useNavigate } from "react-router-dom";
import { buyerApi } from "../../../api/buyerLeadApi";
import { publicApi } from "../../../api/publicApi";
// import { showToast } from "../../../../../components/toaster/Toaster";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import MultiSelect from "../../../components/buttons/multiSelect";
import { Container, Grid, Typography } from "@mui/material";

const Step1 = ({ setCurrentStep, id }) => {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [temporaryStatus, setTemporaryStatus] = useState({});
  const [comments, setComments] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [error, setError] = useState(false);

  const formatPrice = (value) => {
    const number = Number(value.replace(/[^0-9]/g, ""));

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const [customerData, setCustomerData] = useState({
    fuelType: [],
    makeIdList: [],
    modelIdList: [],
    otherRequirement: "",
    priceFrom: null,
    priceTo: null,
    yearFrom: "",
    transmissionType: "",
  });

  const [payload, setPayload] = useState({
    customerId: +id.id,
    statusId: null,
    followDate: null,
    comment: null,
  });

  const [basicDetails, setBasicDetails] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const navigate = useNavigate("");

  const handleStatusChange = (event) => {
    const statusId = event.target.value;
    setPayload((prev) => ({ statusId: statusId }));
    setTemporaryStatus((prevStatus) => ({
      [id]: statusId,
    }));
  };

  const customer = async (params = {}) => {
    try {
      const res = await buyerApi.getCustomerInfo({ leadId: id.path });
      setCustomerList(res?.data);
      setBasicDetails({
        name: res?.data[0]?.name || null,
        mobile: res?.data[0]?.mobile || null,
        email: res?.data[0]?.email || null,
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    customer();
  }, []);

  const [make, setMake] = useState([]);
  const [modal, setModal] = useState([]);

  const [preferences, setPreferences] = useState({
    leadId: +id.path,
    make: [],
    model: [],
    transmissionType: null,
    fuelType: [],
    priceFrom: "",
    priceTo: null,
    yearFrom: null,
    otherRequirement: "",
  });

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    new Array(currentYear - 1999),
    (val, index) => 2000 + index
  );

  const fuelTypeMapping = {
    Petrol: "1",
    Diesel: "2",
    Electric: "3",
    CNG: "4",
  };

  const handleBrandChange = async (selectedItems) => {
    const selectedValues = selectedItems.map((item) => item.value);
    setMake(selectedItems);
    //setCustomerData(prev=>({...prev, makeIdList: selectedValues,}));
    setPreferences((prev) => ({
      ...prev,
      make: selectedValues,
    }));

    if (selectedValues.length > 0) {
      try {
        const res = await buyerApi.getModels({
          make: `[${selectedValues.join(",")}]`,
          is_group_model: true,
        });
        setModels(res.data);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    } else {
      setModels([]);
    }
  };

  const handleModelChange = (selectedItems) => {
    const selectedValue = selectedItems.map((item) => item.value);
    setModal(selectedItems);
    //setCustomerData(prev=>({...prev, modelIdList: selectedValue,}));
    setPreferences((prev) => ({
      ...prev,
      model: selectedValue,
    }));
  };

  const handleFuelTypeChange = (selectedItems) => {
    const selectedValues = selectedItems.map((item) => item.value);
    setPreferences((prev) => ({
      ...prev,
      fuelType: selectedValues,
    }));
  };



  const fetchCustomerPreferences = async () => {
    try {
      const res = await buyerApi.getLeadPrefrence(+id.path);

      const fuelTypeMappings = {
        1: "Petrol",
        2: "Diesel",
        3: "Electric",
        4: "CNG",
      };

      // Extracting lead preferences from the response
      const leadPreferences = res?.data?.leadPrefrences || {};

      // Map the new structure to the expected format
      const preferences = {
        fuelType:
          leadPreferences.fuelType?.map((type) => fuelTypeMappings[type]) || [],
        makeIdList: leadPreferences.makeIdList || [],
        modelIdList: leadPreferences.modelIdList || [],
        otherRequirement: leadPreferences.otherRequirement || "",
        priceFrom: leadPreferences.priceFrom || null,
        priceTo: leadPreferences.priceTo || null,
        yearFrom: leadPreferences.yearFrom || "",
        transmissionType: leadPreferences.transmissionType || "",
      };

      console.log("preferences", preferences);
      setCustomerData(preferences);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchCustomerPreferences();
  }, []);

  // console.log("customerDatacustomerData ", customerData);

  useEffect(() => {
    if (brands.length && customerData.makeIdList.length) {
      const selectedBrands = customerData.makeIdList
        .map((makeId) => {
          const brand = brands.find((brand) => brand.makeId === +makeId);
          return brand ? { label: brand.makeName, value: brand.makeId } : null;
        })
        .filter(Boolean);

      setPreferences((prev) => ({
        ...prev,
        make: selectedBrands,
      }));
      handleBrandChange(selectedBrands);
      setMake(selectedBrands);
    }
  }, [brands, customerData.makeIdList]);

  useEffect(() => {
    if (models.length && customerData.modelIdList.length) {
      const selectedModels = customerData.modelIdList
        .map((modelId) => {
          const model = models.find((model) => model.groupId === +modelId);
          return model
            ? { label: model.groupModelName, value: model.groupId }
            : null;
        })
        .filter(Boolean);

      setPreferences((prev) => ({
        ...prev,
        model: selectedModels,
      }));
      handleModelChange(selectedModels);
      setModal(selectedModels);
    }
  }, [models, customerData.modelIdList]);

  useEffect(() => {
    if (customerData) {
      setPreferences((prevPreferences) => ({
        ...prevPreferences,
        transmissionType: customerData.transmissionType || "",
        fuelType: customerData.fuelType || [],
        priceFrom: customerData.priceFrom || "",
        priceTo: customerData.priceTo || "",
        yearFrom: customerData.yearFrom || "",
        otherRequirement: customerData.otherRequirement || "",
      }));

      setComments(customerData.otherRequirement || "");
    }
  }, [customerData]);

  const fetchBrands = async () => {
    try {
      const res = await publicApi.brandList();
      const filteredBrands = res.data.filter((brand) => brand.makeId >= 2);
      setBrands(filteredBrands);
    } catch (error) {
      console.log(error);
    }
  };

  const formatIndianCurrency = (amount) => {
    if (!amount) return "";

    const x = amount.toString().replace(/\D/g, "");

    const lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);

    const formattedValue =
      otherNumbers !== ""
        ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree
        : lastThree;

    return formattedValue;
  };

  const handlePriceFrom = (e) => {
    const value = e.target.value;
    // const formattedValue = formatIndianCurrency(value);

    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      priceFrom: value.toString().replace(/,/g, ""),
    }));
  };

  const handlePriceTo = (e) => {
    const value = e.target.value;
    const formattedValue = formatIndianCurrency(value);

    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      priceTo: value.toString().replace(/,/g, ""),
    }));
  };

  const handleBack = () => {
    navigate("/buyer-lead-management");
  };

  const handleSave = async () => {
    try {
      if (preferences.priceFrom < 100000) {
        toast("Min Price must be greater than or equal to 1 lakh");
        return;
      }
      if (preferences.priceTo < 100000) {
        toast("Max Price must be greater than or equal to 1 lakh");
        return;
      }

      if (preferences.yearFrom < 2000) {
        toast("Min Year must be greater than or equal to 2000");
        return;
      }
      if (preferences.yearFrom > currentYear) {
        toast(`Max Year must be less than or equal to ${currentYear}`);
        return;
      }
      if (preferences.yearFrom > preferences.yearTo) {
        toast("Min Year cannot be greater than Max Year");
        return;
      }

      preferences.fuelType = preferences.fuelType.map(
        (fuel) => fuelTypeMapping[fuel]
      );

      const response = await buyerApi.saveLeadPrefrence(preferences);
      fetchCustomerPreferences(+id.id);
      customer();
      toast(response.message);
    } catch (error) {
      console.log(error);
      toast(error.message);
    }
  };

  const handleSaveNext = () => {
    setCurrentStep(3);
  };

  const handleYearChange = (e) => {
    const year = e.target.value;
    const currentYear = moment().year();

    if (year > currentYear || year < 2000) {
      setError(true);
    } else {
      setError(false);
    }
    setPreferences((prev) => ({ ...prev, yearFrom: year }));
  };

  return (
    <Container className={style["container"]}>
      <Grid container spacing={2} sx={{ padding: "10px", marginTop: "10px" }}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" className={style["dealer-info-text"]}>
            Name: {basicDetails?.name || ""}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" className={style["dealer-info-text"]}>
            Mobile: {basicDetails?.mobile || ""}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" className={style["dealer-info-text"]}>
            Email: {basicDetails?.email || ""}
          </Typography>
        </Grid>
      </Grid>

      <div className={style["mid-container"]}>
        <div className={style["two-tabs-section"]}>
          <div className={style["input-label"]}>
            <p className={style["label"]}>Select Brand : </p>
            <MultiSelect
              containerStyle={style["selectable-button-container-style"]}
              name="brand"
              handleChange={handleBrandChange}
              items={brands.map((brand) => ({
                label: brand.makeName,
                value: brand.makeId,
              }))}
              selectedItems={make}
              hint={"Select Brands"}
              isMulti={true}
            />
          </div>
        </div>

        <div className={style["two-tabs-section"]}>
          <div className={style["input-label"]}>
            <p className={style["label"]}>Select Model : </p>
            <MultiSelect
              containerStyle={style["selectable-button-container-style"]}
              name="model"
              handleChange={handleModelChange}
              items={models.map((model) => ({
                label: model.groupModelName,
                value: model.groupId,
              }))}
              selectedItems={modal}
              hint={"Select Models"}
              isMulti={true}
            />
          </div>
        </div>

        <div className={style["two-tabs-section"]}>
          <div className={style["input-label"]}>
            <p className={style["label"]}>Fuel Type : </p>
            <MultiSelect
              containerStyle={style["selectable-button-container-style"]}
              name="fuelType"
              handleChange={handleFuelTypeChange}
              items={[
                { label: "Petrol", value: "Petrol" },
                { label: "Diesel", value: "Diesel" },
                { label: "CNG", value: "CNG" },
                { label: "Electric", value: "Electric" },
              ]}
              selectedItems={preferences.fuelType.map((fuel) => ({
                label: fuel,
                value: fuel,
              }))}
              hint={"Select Fuel Types"}
              isMulti={true}
            />
          </div>
        </div>

        <div className={style["two-tabs-section"]}>
          <div className={style["input-label"]}>
            <p className={style["label"]}>Transmission : </p>
            <select
              value={preferences.transmissionType}
              className={style["dropdown-container"]}
              onChange={(e) =>
                setPreferences((prev) => ({
                  ...prev,
                  transmissionType: e.target.value,
                }))
              }
            >
              <option value="">Select Transmission</option>
              <option value="2">Automatic</option>
              <option value="1">Manual</option>
            </select>
          </div>
        </div>

        {/* <div className={style["two-tabs-section"]}>
          <div className={style["input-label"]}>
            <p className={style["label"]}>Minimum Model Year : </p>
            <select
              value={preferences.yearFrom}
              className={style["dropdown-container"]}
              onChange={(e) => setPreferences(prev =>({...prev, yearFrom: e.target.value}))}
            >
              <option value="">Select Year</option>
              {years.map((year, index) => (
                <option key={index} value={year}>{year}</option>
              ))}
            </select>
          </div>
        </div> */}
        <div className={style["two-tabs-section"]}>
          <div className={style["input-label"]}>
            <p className={style["label"]}>Minimum Model Year : </p>
            <input
              type="number"
              value={preferences.yearFrom}
              className={`${style["input-container"]} ${
                error ? style["input-error"] : ""
              }`}
              onChange={handleYearChange}
              min="2000"
              max={moment().year()} // Dynamically setting the max to current year
              placeholder="Enter Year"
            />
            {error && (
              <p className={style["error-text"]}>
                Year must be between 2000 and {moment().year()}.
              </p>
            )}
          </div>
        </div>

        {/* <div className={style["two-tabs-section"]}>
          <div className={style["input-label"]}>
            <p className={style["label"]}>Owners : </p>
            <select
              value={preferences.ownerType}
              className={style["dropdown-container"]}
              onChange={(e) =>
                setPreferences((prev) => ({
                  ...prev,
                  ownerType: +e.target.value,
                }))
              }
            >
              <option value="">Select Owners</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="3">4</option>
              <option value="3">5</option>
            </select>
          </div>
        </div> */}

        <div className={style["two-tabs-section"]}>
          <div className={style["input-label"]}>
            <p className={style["label"]}>Price Range </p>
            <div className={style["price-container"]}>
              <input
                type="text"
                placeholder="Min Price"
                className={style["price-container"]}
                value={formatIndianCurrency(preferences.priceFrom)}
                onChange={(e) => handlePriceFrom(e)}
              />
              {/* <span className={style['-span']}>-</span> */}
              <input
                type="text"
                placeholder="Max Price"
                className={style["price-container"]}
                value={formatIndianCurrency(preferences.priceTo)}
                onChange={(e) => handlePriceTo(e)}
              />
            </div>
          </div>
        </div>

        <div className={style["two-tabs-section"]}>
          <div className={style["input-label"]}>
            <p className={style["label"]}>Additional Comments :</p>
            <textarea
              value={comments}
              onChange={(e) => {
                setComments(e.target.value);
                setPreferences((prevPreferences) => ({
                  ...prevPreferences,
                  otherRequirement: e.target.value,
                }));
              }}
              placeholder="Enter any additional comments here..."
              className={style["textarea"]}
            />
          </div>
        </div>

        <div className={style["save-button-section"]}>
          <button onClick={handleBack} className={style["save-button"]}>
            Back
          </button>
          <button onClick={handleSave} className={style["save-button"]}>
            Save
          </button>

          {/* <button onClick={handleSaveNext} className={style["save-button"]}>
            Save & Next
          </button> */}
        </div>
      </div>
      <ToastContainer />
    </Container>
  );
};

export default Step1;
