import { Formik, Form } from "formik";
import MultiSelect from "../../components/buttons/multiSelect"
import style from "./style.module.scss";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import InputField from "../../components/input-field/InputField";
import { R } from "../../constants/resources";
import SelectableButton from "../../components/buttons/SelectableButton";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect, useState } from "react";
import { publicApi } from "../../api/publicApi.js";
import { carApi } from "../../api/carApi.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { showToast } from "../../components/toaster/toaster.js";
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'

function PriceCounting() {

    const initalValues = {
        makeId: "",
        modelId: "",
        variantId: "",
        kmDriven: "",
        makeYear: "",
        fuelType: "",
        ownerType: "",
        city: ""
    }

    const [varientList, setVarientList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [imageTypeEnum, setimageTypeEnum] = useState([]);
    const [selectModelId, setModlId] = useState(0);
    const [selectedMakeId, setMakeId] = useState(0);
    const [selectMakeYear, setMakeYear] = useState(0);
    const [selectedFuelType, setSelectedFuelType] = useState(0);
    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (values) => {
        setLoading(true)
        try {
            const getVarient = varientList.find(item => item?.variantId == values?.variantId);
            const getModelList = modelList.find(item => item?.modelId == values?.modelId);
            const data = {
                centralVariantId: getVarient?.centralVariantId,
                makeYear: values?.makeYear,
                owners: values?.ownerType,
                kmDriven: +values?.kmDriven,
                city: values?.city,
                groupModelId : getModelList?.groupId,
                fuelType : selectedFuelType
            }
            const res = await publicApi.truePrice(data);
            setResponse(res);
            showToast("success", "success")
        } catch (error) {
            console.log(error?.response?.data?.message);
            setResponse(error?.response?.data)
            showToast("error", error?.response?.data?.message);
        } finally {
            setLoading(false); // Loader stop
        }

    }

    const varients = async () => {
        try {
            const res = await publicApi.varientList({ model: selectModelId, fuel_type: selectedFuelType });
            setVarientList(res?.data);
        } catch (err) {
            console.log(err);
        }
    };

    const brands = async () => {
        try {
            const res = await publicApi.brandList();
            setBrandList(res?.data);
            const res1 = await carApi.imgTypeEnumList();
            setimageTypeEnum(res1);
            setResponse([])
        } catch (err) {
            console.log(err);
        }
    };

    const models = async () => {
        try {
            const res = await publicApi.modelList({
                make: selectedMakeId,
                year: selectMakeYear,
            });
            setModelList(res?.data);
            setResponse([]);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        brands()
    }, [])

    useEffect(() => {
        if (selectedMakeId, selectMakeYear) {
            models();
        }
    }, [selectedMakeId, selectMakeYear])

    useEffect(() => {
        if (selectModelId && selectedFuelType) {
            varients();
        }
    }, [selectModelId, selectedFuelType])

    const handleRegYearChange = (newValue, setFieldValue) => {
        const year = moment(newValue).year();
        setFieldValue("makeYear", year);
        setMakeYear(year);
    };


    return (

        <Formik initialValues={initalValues} onSubmit={handleSubmit}>
            {({ handleChange, values, errors, touched, setFieldValue }) => (
                <Form className={style['formik']}>
                    <div className={style['Heading']}>
                        <h1>PRICING TOOL</h1>
                    </div>
                    <div className={style['wrapper']}>
                        <div className={style['header-wrapper']}>
                            <div className={style['first-container']}>
                                <div className={style['input-wrapper']}>
                                    <p className={style["label"]}>
                                        Make
                                    </p>
                                    <div className={style['select-desin']}>
                                        <MultiSelect
                                            name="makeId"
                                            handleChange={(e) => {
                                                setMakeId(e.value);
                                                setFieldValue("makeId", e.value)
                                                setResponse([]);
                                            }}
                                            items={brandList?.map((ele) => ({
                                                label: ele?.makeName,
                                                value: ele?.makeId,
                                            }))}
                                            selectedItems={values?.makeId}
                                            hint={"Select Brand"}
                                            isSearchable={true}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className={style['input-wrapper']}>
                                    <p className={style["label"]}>
                                        Model
                                    </p>
                                    <div className={style['select-desin']}>
                                        <MultiSelect
                                            name="modelId"
                                            handleChange={(e) => {
                                                setFieldValue('modelId', e.value);
                                                setModlId(e.value)
                                                setResponse([]);
                                            }}
                                            items={modelList?.map((ele) => ({
                                                label: ele?.groupModelName,
                                                value: ele?.modelId,
                                            }))}
                                            selectedItems={values?.modelId}
                                            hint={"Select Model"}
                                            isSearchable={true}
                                            containerStyle={style["selectable-button-container-style"]}
                                            selectClass={style["filterDropDown"]}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className={style['input-wrapper']}>
                                    <p className={style["label"]}>
                                        Varient
                                    </p>
                                    <div className={style['select-desin']}>
                                        <MultiSelect
                                            name="variantId"
                                            handleChange={(e) => {
                                                setFieldValue('variantId', String(e.value))
                                                setResponse([]);
                                            }}
                                            items={varientList?.map((ele) => ({
                                                label: ele?.variantName,
                                                value: ele?.variantId,
                                            }))}
                                            selectedItems={values?.variantId}
                                            hint={"Select Varient"}
                                            containerStyle={style["selectable-button-container-style"]}
                                            selectClass={style["filterDropDown"]}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className={style['input-wrapper']}>
                                    <p className={style["label"]}>
                                        KM Driven
                                    </p>
                                    <div className={style['select-desin']}>
                                        <InputField
                                            placeholder={"Enter KM Driven"}
                                            name="kmDriven"
                                            handleChange={(e) => {
                                                setFieldValue("kmDriven", e.target.value);
                                                setResponse([]);
                                            }}
                                            value={values?.kmDriven}
                                            length={6}
                                            regexPattern={/^\d+$/}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={style['second-container']}>
                                <div className={style['input-wrapper']}>
                                    <p className={style["label"]}>
                                        Make Year
                                    </p>
                                    <div className={style['select-desin']}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale="de"
                                            sx={{
                                                "& .MuiStack-root": {
                                                    paddingTop: "10px !important",
                                                },
                                            }}
                                        >
                                            <DatePicker
                                                size="small"
                                                openTo="year"
                                                views={["year"]}
                                                value={
                                                    values.makeYear ? moment().year(values.makeYear) : null
                                                }
                                                onChange={(newValue) => handleRegYearChange(newValue, setFieldValue)}
                                                sx={{
                                                    width: "100%",
                                                    "& .MuiInputBase-root": {
                                                        height: "36px !important",
                                                    },
                                                    "& .MuiStack-root": {
                                                        paddingTop: "0px !important",
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className={style['input-wrapper']}>
                                    <p className={style["label"]}>
                                        Fuel Type
                                    </p>
                                    <div className={style['select-desin']}>
                                        <MultiSelect
                                            name="fuelType"
                                            selectedItems={values?.fuelType}
                                            handleChange={(e) => {
                                                console.log(typeof e.value);
                                                setFieldValue('fuelType', String(e.value));
                                                setSelectedFuelType(e.label);
                                                setResponse([]);
                                            }}
                                            items={[
                                                { label: "Petrol", value: 1 },
                                                { label: "Deisel", value: 2 },
                                                { label: "Electric", value: 3 },
                                                { label: "CNG", value: 4 },
                                            ]}
                                            hint={"Select Fuel Type"}
                                            containerStyle={style["selectable-button-container-style"]}
                                            selectClass={style["filterDropDown"]}
                                        />
                                    </div>
                                </div>
                                <div className={style['input-wrapper']}>
                                    <p className={style["label"]}>
                                        Owner
                                    </p>
                                    <div className={style['select-desin']}>
                                        <SelectableButton
                                            trailingIcon={R.ic_arrow_down}
                                            containerStyle={style["selectable-button-container-style"]}
                                            name="ownerType"
                                            handleChange={(e) => {
                                                setFieldValue('ownerType', +e.target.value);
                                                setResponse([]);
                                            }}
                                            items={[
                                                { label: "First", value: 1 },
                                                { label: "Second", value: 2 },
                                                { label: "Third", value: 3 },
                                                { label: "Fourth", value: 4 },
                                                { label: "Fourth+", value: 5 },
                                            ]}
                                            value={values?.ownerType}
                                            hint={"Select Owner"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className={style['input-wrapper']}>
                                    <p className={style["label"]}>
                                        City
                                    </p>
                                    <div className={style['select-desin']}>
                                        <InputField
                                            type={"text"}
                                            placeholder={"Enter City Name"}
                                            name="city"
                                            handleChange={(e) => {
                                                setFieldValue("city", e.target.value);
                                                setResponse([]);
                                            }}
                                            value={values?.city}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style['action-wrapper']}>
                            <button
                                fontSize={"1rem"}
                                className={style["primary-button-container-style"]}
                                type={"submit"}
                            >
                                {loading ? "Loading..." : "Submit"}
                            </button>
                        </div>
                        {loading && <div className={style['loader']}>Loading...</div>}
                        {!loading && (
                            <div className={style['json-formater']}>
                                <div className={style['left-side']}>
                                    <div className="d-flex flex-column mb-4">
                                        <label>Max Price</label>
                                        <input
                                            type="text"
                                            value={response?.maxPrice || ""}
                                            disabled
                                        />
                                    </div>
                                    <div className="d-flex flex-column mb-4">
                                        <label>Medium price</label>
                                        <input
                                            type="text"
                                            value={response?.medianPrice || ""}
                                            disabled
                                        />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <label>Minimum price</label>
                                        <input
                                            type="text"
                                            value={response?.minPrice || ""}
                                            disabled
                                        />
                                    </div>

                                </div>

                                <div className={style['right-side']}>
                                    <div style={{ padding: "20px", background: "#f5f5f5", borderRadius: "8px" }}>
                                        <JsonView src={typeof response === "string" ? JSON.parse(response) : response} />
                                    </div>
                                </div>

                            </div>
                        )}
                    </div>

                </Form>
            )}
        </Formik>


    )
}
export default PriceCounting;