import { GET_BLOGS, SAVE_BLOGS } from "../types";

const initalstate = {
    blogsList: [],
    blogData: {
        blogDetails : {}
    }
}

export default function (state = initalstate, action) {
    const { type, payload } = action;
    switch (type) {
        case SAVE_BLOGS:
            return {
                ...state,
                blogData: { ...state?.blogData, blogDetails: payload }
            }
        case GET_BLOGS:
            return {
                ...state, blogsList: payload.data
            }

        default:
            return state;
    }
}