import React, { useEffect, useState } from "react";
import style from "../add-new-car/addNewCar.module.scss";
import ImageCard from "../add-new-car/components/image-card/ImageCard";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import SelectableButton from "../../components/buttons/SelectableButton";
import InputField from "../../components/input-field/InputField";
import BackButton from "../../components/buttons/BackButton";
import { postData } from "../../components/services/apiServices";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { publicApi } from "../../api/publicApi";
import { editCarrDetails, fetchPurchesar, fetchSource } from "../../store/car/carAction";
import { carApi } from "../../api/carApi";
import MultiSelect from "../../components/buttons/multiSelect";
import DateField from "../../components/input-field/dateField";
import moment from "moment";
import { toast } from "react-toastify";
import SecondaryHeading from "../../components/primary-heading/SecondaryHeading";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ErrorMessage from "../../components/input-field/ErrorMessage.jsx";
import { formatIndianCurrency } from "../../utils/PrivateRoutes.js";
import { geographicRegion } from "../../api/geoRegion.js";
import { Box, Grid, Typography } from "@mui/material";
import { fetchDealerAddress } from "../../store/car/carAction";

export default function EditCarDetails() {
  const [resData, setRes] = useState([]);
  const [colorId, setColorId] = useState("");
  const [coloList, setColorList] = useState([]);
  useEffect(() => {
    if (coloList.length && resData?.colour) {
      const foundColor = coloList.find((res) => res?.colorName?.toLowerCase() === resData?.colour.toLowerCase());
      setColorId(foundColor?.colorId || "");
    }
  }, [coloList, resData]);

  const { purchaser, source, dealerAddress } = useSelector((state) => state?.car || []);

  const [formData, setFormData] = useState({
    is_inventory: "update",
    carSkuId: "",
    regNumber: "",
    makeId: null,
    fuelType: "",
    showroomId: "",
    status: "",
    transmissionType: null,
    images: null,
    makeYear: null,
    regYear: null,
    mspPrice: "",
    ownerType: null,
    kmDriven: null,
    discounted_price: "",
    listingPrice: "",
    discountPercent: "",
    modelId: "",
    variantId: "",
    insuranceType: "",
    insur_validity_dt: null,
    insuranceExpMonth: "",
    insuranceExpYear: "",
    isCngFitted: 0,
    isClassified: 0,
    isWebsite: 0,
    isAssured: 0,
    carDescription: "",
    metaTag: "",
    carStatus: "",
    msp_price: "",
    colour: "",
    trending: 0,
    discounted: 0,
    newModel: 0,
    newStock: 0,
    state: "",
    regCityId: "",
    source: "",
    purchaser: ""
  });
  const dispatch = useDispatch();
  const [loaderTrue, setloaderTrue] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [varientList, setVarientList] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [alreadyImg, setalreadyImg] = useState([]);
  const [flags, setFlags] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [imageTypeEnum, setimageTypeEnum] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [requiredError, setRequiredError] = useState("");
  const [geoRegion, setGeoRegion] = useState({ state: [], city: [] });
  // const [dealerAddress, setDealerAddress] = useState([]);

  const fuelTypes = {
    1: "Petrol",
    2: "Diesel",
    4: "CNG",
    3: "Electric (Battery)"
  };


  useEffect(() => {
    dispatch(fetchDealerAddress());
    dispatch(fetchPurchesar());
    dispatch(fetchSource())
  }, [dispatch]);

  const brands = async () => {
    try {
      const res = await publicApi.brandList();
      setBrandList(res?.data);
      const res1 = await carApi.imgTypeEnumList();
      setimageTypeEnum(res1);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRegYearChange = (newValue) => {
    const year = moment(newValue).year();
    setFormData((prevFormData) => ({
      ...prevFormData,
      makeYear: year,
    }));
  };

  const handleallYearChange = (newValue, name) => {
    const year = moment(newValue).year();

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: year,
    }));
  };

  const getCarDetailById = async (data) => {
    const requestBody = {
      sku_id: [state.data.carSkuId],
    };

    try {
      setloaderTrue(true);
      const res = await carApi.getCarList(requestBody);
      const data = res.data[0];
      setRes(data);
      setFormData({
        carSkuId: data?.carSkuId || "",
        regNumber: data?.reg_no || "",
        listingPrice: data?.carPrice || "",
        makeId: data?.makeId || null,
        fuelType: data?.fuelType || "",
        showroomId: data?.showroomId || "",
        status: data?.status || "",
        transmissionType: data?.transmissionid || null,
        makeYear: data?.makeYear || null,
        regYear: data?.reg_year || null,
        mspPrice: data?.msp_price || "",
        ownerType: data?.ownerType || null,
        kmDriven: data?.drivenKms || null,
        discounted_price: state?.data?.discounted_price || "",
        discountPercent: data?.discountPercent || 0,
        modelId: data?.modelId || "",
        variantId: data?.varientId || "",
        insuranceExpMonth:
          data?.insurance_type_id !== 5 ? data?.insurance_exp_month : "",
        insuranceExpYear:
          data?.insurance_type_id !== 5 ? data?.insurance_exp_year : "",
        insuranceType: data?.insurance_type_id || null,
        carDescription: data?.car_description || "",
        metaTag: data?.metaTag || "",
        carStatus: data?.car_status || "",
        isAssured: data?.isAssured || 0,
        isClassified: +data?.is_classified || 0,
        isWebsite: + data?.is_website || 0,
        isCngFitted: +data?.is_cng_fitted || 0,
        trending: data?.trending || 0,
        discounted: data?.discounted || 0,
        newModel: data?.new_model || 0,
        newStock: data?.newStock || 0,
        state: data?.stateId || "",
        regCityId: data?.city_id || "",
        showroomId: data.showroom_id || "",
        source: data?.metaSource || "",
        purchaser: data?.purchaser || ""
      });
      setloaderTrue(false);
      setalreadyImg(res["data"] || []);
    } catch (err) {
      console.log(err);
      setloaderTrue(false);
    }
  };

  const fetchAndSetGeoRegions = async () => {
    try {
      const stateData = await geographicRegion.getStateNames();
      setGeoRegion((prev) => ({ ...prev, state: stateData?.data }));
      const stateId = formData?.state;
      if (stateId) {
        const cityData = await geographicRegion.getCityNames({
          state: stateId,
        });
        setGeoRegion((prev) => ({ ...prev, city: cityData?.data }));
      } else {
        setGeoRegion((prev) => ({ ...prev, city: [] }));
        setFormData((prev) => ({ ...prev, city: "", state: "" }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAndSetGeoRegions();
  }, [formData.state]);

  const colors = async () => {
    try {
      const res = await publicApi.getColors();
      setColorList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    colors();
  }, []);


  useEffect(() => {
    if (formData?.makeId && formData.makeYear) {
      models();
    }
  }, [formData?.makeId, formData.makeYear]);

  useEffect(() => {
    if (formData?.fuelType) varients();
  }, [formData?.fuelType]);

  const models = async () => {
    try {
      const res = await publicApi.modelList({
        make: formData?.makeId,
        year: formData?.makeYear,
      });
      setModelList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const varients = async () => {
    try {
      let fuelTypeVersion = fuelTypes[formData.fuelType];
      const res = await publicApi.varientList({ model: formData?.modelId, fuel_type: fuelTypeVersion });
      setVarientList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    brands();
    getCarDetailById(state?.data?.car_id);
  }, [state?.data?.car_id]);

  // useEffect(() => {
  //   if (formData?.makeId && formData?.model_year) {
  //     models();
  //   }
  // }, [formData?.makeId, formData?.model_year]);

  // useEffect(() => {
  //   if (formData?.modelId) varients();
  // }, [formData?.modelId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]:
        name === "listingPrice" ? value.toString().replace(/,/g, "") : value,
    });
  };

  const editCar = async (e) => {
    e.preventDefault();

    if (formData.insuranceType == "5") {
      delete formData.insuranceExpYear;
      delete formData.insuranceExpMonth;
    }

    if (formData?.discountPercent < 0 || formData?.discountPercent > 99.99 || isNaN(formData?.discountPercent)) {
      return;
    }



    if (
      !formData.makeId ||
      !formData.fuelType ||
      !formData.transmissionType ||
      !formData?.variantId ||
      !formData.modelId ||
      !formData.makeYear ||
      !formData.listingPrice
    ) {
      setRequiredError(true);
      return;
    }
    setLoading(true);
    setloaderTrue(true);
    try {
      const updatedFormData = {
        ...formData,

        regNumber: formData.regNumber ? formData.regNumber : undefined,
        regYear: formData.regYear ? formData.regYear : undefined,
        regCityId: formData.regCityId ? formData.regCityId : undefined,
        state: formData.state ? formData.state : undefined,
        insuranceExpMonth: formData.insuranceExpMonth
          ? formData.insuranceExpMonth
          : undefined,
        insuranceExpYear: formData.insuranceExpYear
          ? formData.insuranceExpYear
          : undefined,
        insuranceType: formData.insuranceType
          ? formData.insuranceType
          : undefined,
        isAssured: formData.isAssured ? formData.isAssured : undefined,
        insuranceType: formData.insuranceType ? formData.insuranceType : "0",
        is_inventory: "update",
        trending: String(formData.trending),
        discounted: String(formData.discounted),
        newModel: String(formData.newModel),
        newStock: String(formData.newStock),
        isAssured: String(formData.isAssured),
        isClassified: String(formData.isClassified),
        isWebsite: String(formData.isWebsite),
        isCngFitted: String(formData.isCngFitted),
        insuranceExpMonth: parseInt(formData.insuranceExpMonth, 10),
        mspPrice:
          Math.ceil(
            (Number(formData?.listingPrice) *
              Number(formData?.discountPercent)) /
            100
          ) + Number(formData?.listingPrice),
        discountPercent: formData.discountPercent
          ? parseInt(formData.discountPercent, 10)
          : 0,
      };
      if (updatedFormData.purchaser === "") {
        delete updatedFormData.purchaser
      }
      if (updatedFormData.source === "") {
        delete updatedFormData.source

      }

      const response = await carApi.editCarDetails(updatedFormData);

      if (response?.status === true) {
        navigate("/car-management");
      } else {
        setLoading(false);
        setloaderTrue(false);
        response?.message?.map((errr) =>
          toast.error(errr || "Something went wrong.")
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.msg || "Internal Server Error");
      setLoading(false);
      setloaderTrue(false);
    }
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      insuranceExpMonth: selectedMonth,
    }));
  };

  const handleYearChange = (newValue, name) => {
    const year = moment(newValue).year();

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: year,
    }));
  };


  const handleCheckbox = (event, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: event.target.checked ? 1 : 0,
    }));
  };



  const InventryMetaData = localStorage.getItem('is_inventory_metadata');


  return (
    <div className={style["container"]}>
      {loaderTrue ? (
        <div class="loading">
          <div class="loader"></div>
        </div>
      ) : null}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          alignItems: "center",
          padding: "15px ",
        }}
      >
        <BackButton
          onClick={() => {
            navigate("/car-management");
          }}
        />
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          Edit Car Details
        </Typography>
      </Box>

      <div className={style["wrapper"]}>
        <div className={style["vehicle-details-container"]}>
          <SecondaryHeading
            title={"Vehicle Details"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />

          <div className={style['first-container']}>
            <div className={style['left-side-current']}>
              <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  Make <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style['select-desin']}>
                  <MultiSelect
                    name="makeId"
                    handleChange={(e) =>
                      setFormData({ ...formData, makeId: e.value })
                    }
                    items={brandList?.map((ele) => ({
                      label: ele?.makeName,
                      value: ele?.makeId,
                    }))}
                    selectedItems={formData?.makeId}
                    hint={"Select Brand"}
                    isSearchable={true}
                    required={true}
                  />
                  <ErrorMessage
                    errorMessage={!formData?.makeId && "Please select brand"}
                    requiredError={
                      !formData?.makeId?.length > 0 ? requiredError : ""
                    }
                  />

                </div>
              </div>

              <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  Model <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style['select-desin']}>
                  <MultiSelect
                    name="modelId"
                    handleChange={(e) =>
                      setFormData({ ...formData, modelId: e.value })
                    }
                    items={modelList?.map((ele) => ({
                      label: ele?.groupModelName,
                      value: ele?.modelId,
                    }))}
                    selectedItems={formData?.modelId}
                    hint={"Select Model"}
                    isSearchable={true}
                    containerStyle={style["selectable-button-container-style"]}
                    selectClass={style["filterDropDown"]}
                    required={true}
                  />
                  <ErrorMessage
                    errorMessage={!formData?.modelId && "Please select model"}
                    requiredError={!formData?.modelId?.length > 0 && requiredError}
                  />

                </div>
              </div>

              <div className={style["input-wrapper"]}>
                <p className={style["label"]}>
                  Transmission <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style['select-desin']}>
                  <SelectableButton
                    trailingIcon={R.ic_arrow_down}
                    containerStyle={style["selectable-button-container-style"]}
                    name="transmissionType"
                    handleChange={(e) => {
                      setFormData({
                        ...formData,
                        transmissionType: parseInt(e.target.value, 10),
                      });
                    }}
                    items={[
                      { label: "Manual", value: 1 },
                      { label: "Automatic", value: 2 },
                    ]}
                    value={formData?.transmissionType}
                    hint={"Select Transmission"}
                    required={true}
                  />
                  <ErrorMessage
                    errorMessage={
                      !formData?.transmissionType && "Please select transmission"
                    }
                    requiredError={
                      !formData?.transmissionType?.length > 0 && requiredError
                    }
                  />

                </div>
              </div>




              <div className={style['input-wrapper']}>
                <p className={style["label"]} style={{ marginBottom: 10 }}>
                  Reg Year <span style={{ color: "red" }}></span>
                </p>
                <div className={style['select-desin']}>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="de"
                    sx={{
                      "& .MuiStack-root": {
                        paddingTop: "0px !important",
                      },
                    }}
                  >
                    <DatePicker
                      size="small"
                      openTo="year"
                      views={["year"]}
                      minDate={
                        formData.makeYear ? moment(`${formData.makeYear}-01-01`) : undefined
                      }
                      maxDate={moment(`${new Date().getFullYear()}-12-31`)}

                      required={true}

                      value={
                        formData.regYear
                          ? moment().year(formData.regYear) // Agar regYear select hai, toh uska value dikhaye.
                          : formData.makeYear
                            ? moment().year(formData.makeYear) // Agar regYear select nahi hai, toh makeYear ka value dikhaye.
                            : null // Dono me se kuch nahi hai toh null set kare.
                      }

                      onChange={(newValue) => handleYearChange(newValue, "regYear")}
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          height: "36px !important",
                        },
                        "& .MuiStack-root": {
                          paddingTop: "0px !important",
                        },
                      }}
                    />
                    {/* </DemoContainer> */}
                  </LocalizationProvider>

                </div>
              </div>
              <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  KMs Driven <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style['select-desin']}>
                  <InputField
                    placeholder={"Enter KM Driven"}
                    name="kmDriven"
                    handleChange={handleChange}
                    value={formData?.kmDriven}
                    length={6}
                    regexPattern={/^\d+$/}
                    errorMessage={
                      formData?.kmDriven
                        ? "Km should be valid"
                        : "Please enter driven km"
                    }
                    requiredError={!formData?.kmDriven?.length > 0 && requiredError}
                    autoComplete="off"
                  />
                </div>
              </div>


              <div className={style["input-wrapper"]}>
                <p className={style["label"]}>
                  Insurance Type <span style={{ color: "red" }}></span>
                </p>
                <div className={style['select-desin']}>
                  <SelectableButton
                    trailingIcon={R.ic_arrow_down}
                    name="insuranceType"
                    handleChange={(e) => {
                      setFormData({
                        ...formData,
                        insuranceType: e.target.value,
                      });
                    }}
                    items={[
                      { description: "Comprehensive", id: 1 },
                      { description: "Zero Dep", id: 2 },
                      { description: "Own Damage", id: 3 },
                      { description: "Third Party", id: 4 },
                      { description: "No Insurance", id: 5 },
                    ]?.map((ele) => ({
                      label: ele?.description,
                      value: ele?.id,
                    }))}
                    value={formData?.insuranceType}
                    hint={"Select insurance type"}
                    required={true}
                  />
                </div>
              </div>
              <div className={style["input-wrapper"]}>
                {formData?.insuranceType !== "5" ? <>
                  <p className={style["label"]}>Insurance Valid Month</p>
                  <div className={style['select-desin']}>
                    <SelectableButton
                      trailingIcon={R.ic_arrow_down}
                      name="insuranceExpMonth"
                      handleChange={handleMonthChange}
                      items={[
                        { id: 1, name: "January" },
                        { id: 2, name: "February" },
                        { id: 3, name: "March" },
                        { id: 4, name: "April" },
                        { id: 5, name: "May" },
                        { id: 6, name: "June" },
                        { id: 7, name: "July" },
                        { id: 8, name: "August" },
                        { id: 9, name: "September" },
                        { id: 10, name: "October" },
                        { id: 11, name: "November" },
                        { id: 12, name: "December" },
                      ]?.map((ele) => ({
                        label: ele?.name,
                        value: ele?.id,
                      }))}
                      value={
                        formData?.insuranceType !== "5"
                          ? formData?.insuranceExpMonth
                          : ""
                      }
                      hint={"Select insurance expiry month"}
                      required={true}
                    />
                  </div>
                </> : null}
              </div>

              <div className={style["input-wrapper"]}>
                <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.isCngFitted}
                    onChange={(e) => handleCheckbox(e, "isCngFitted")}
                  />
                  <span style={{ paddingLeft: "10px" }}>Is CNG Fitted</span>
                </label>
              </div>

              {/* <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  City <span style={{ color: "red" }}></span>
                </p>
                <div className={style['select-desin']}>
                  <MultiSelect
                    name="regCityId"
                    handleChange={(e) =>
                      setFormData({ ...formData, regCityId: e.value })
                    }
                    items={geoRegion.city?.map((ele) => ({
                      label: ele?.cityName,
                      value: ele?.city_id,
                    }))}
                    selectedItems={formData?.regCityId}
                    hint={"Select City"}
                    containerStyle={style["selectable-button-container-style"]}
                    selectClass={style["filterDropDown"]}
                    required={true}
                  />
                </div>
              </div> */}



            </div>
            <div className={style['right-side-current']}>
              <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  Make Year <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style['select-desin']}>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="de"
                    sx={{
                      "& .MuiStack-root": {
                        paddingTop: "10px !important",
                      },
                    }}
                  >
                    <DatePicker
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          height: "36px !important",
                        },
                        "& .MuiStack-root": {
                          paddingTop: "0px !important",
                        },
                      }}
                      minDate={moment("2000-01-01")}
                      maxDate={moment(`${new Date().getFullYear()}-12-31`)}
                      size="small"
                      openTo="year"
                      views={["year"]}
                      value={
                        formData?.makeYear
                          ? moment().year(formData?.makeYear)
                          : null
                      }
                      onChange={(newValue) =>
                        handleYearChange(newValue, "makeYear")
                      }
                      required={true}
                    />
                  </LocalizationProvider>
                  <ErrorMessage
                    errorMessage={!formData.makeYear && "Please select year"}
                    requiredError={!formData.makeYear?.length > 0 && requiredError}
                  />


                  <ErrorMessage
                    errorMessage={!formData.makeYear && "Please select year"}
                    requiredError={!formData.makeYear?.length > 0 && requiredError}
                  />
                </div>
              </div>
              <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  Fuel Type <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style['select-desin']}>
                  <SelectableButton
                    trailingIcon={R.ic_arrow_down}
                    containerStyle={style["selectable-button-container-style"]}
                    name="fuelType"
                    handleChange={(e) => {
                      setFormData({ ...formData, fuelType: e.target.value });
                    }}
                    items={[
                      { label: "Petrol", value: 1 },
                      { label: "Deisel", value: 2 },
                      { label: "Electric", value: 3 },
                      { label: "CNG", value: 4 },
                    ]}
                    value={formData?.fuelType}
                    hint={"Select Fuel Type"}
                    required={true}
                  />
                  <ErrorMessage
                    errorMessage={!formData?.fuelType && "Please select fuel"}
                    requiredError={!formData?.fuelType?.length > 0 && requiredError}
                  />

                </div>
              </div>
              <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  Variant <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style['select-desin']}>
                  <MultiSelect
                    name="variantId"
                    handleChange={(e) => {
      
                      setFormData({ ...formData, variantId: e.value });
                    }}
                    items={varientList?.map((ele) => ({
                      label: ele?.variantName,
                      value: ele?.variantId,
                    }))}
                    selectedItems={formData?.variantId}
                    hint={"Select Variant"}
                    containerStyle={style["selectable-button-container-style"]}
                    selectClass={style["filterDropDown"]}
                    required={true}
                  />
                  <ErrorMessage
                    errorMessage={!formData?.variantId && "Please select variant"}
                    requiredError={
                      !formData?.variantId?.length > 0 && requiredError
                    }
                  />

                </div>
              </div>
              <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  Vehicle Number <span style={{ color: "red" }}></span>
                </p>
                <div className={style['select-desin']}>
                  <InputField
                    placeholder={"Vehicle Number"}
                    name="regNumber"
                    handleChange={handleChange}
                    value={formData?.regNumber?.toUpperCase()}
                    regexPattern={/^(?=[A-Za-z]{2})(?=.*[A-Z]).{2,11}$/}
                    errorMessage={
                      formData.regNumber
                        ? "Invalid Vehicle Number"
                        : "Please select vehicle number"
                    }
                    autoComplete="off"
                  // requiredError={
                  //   !formData.regNumber.length > 0 ? requiredError : ""
                  // }
                  />
                </div>
              </div>

              <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  Owner Sr No <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style['select-desin']}>
                  <SelectableButton
                    trailingIcon={R.ic_arrow_down}
                    containerStyle={style["selectable-button-container-style"]}
                    name="ownerType"
                    handleChange={(e) => {
                      setFormData({ ...formData, ownerType: e.target.value });
                    }}
                    items={[
                      { label: "First", value: 1 },
                      { label: "Second", value: 2 },
                      { label: "Third", value: 3 },
                      { label: "Fourth", value: 4 },
                    ]}
                    value={formData?.ownerType}
                    hint={"Select Owner"}
                    required={true}
                  />
                  <ErrorMessage
                    errorMessage={!formData?.ownerType && "Please select owner"}
                    requiredError={
                      !formData?.ownerType?.length > 0 && requiredError
                    }
                  />

                </div>
              </div>
              {/* <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  State <span style={{ color: "red" }}></span>
                </p>
                <div className={style['select-desin']}>
                  <MultiSelect
                    name="state"
                    handleChange={(e) =>
                      setFormData({ ...formData, state: e.value })
                    }
                    items={geoRegion.state?.map((ele) => ({
                      label: ele?.stateName,
                      value: ele?.stateListId,
                    }))}
                    selectedItems={formData?.state}
                    hint={"Select State"}
                    containerStyle={style["selectable-button-container-style"]}
                    selectClass={style["filterDropDown"]}
                    required={true}
                  />
                </div>
              </div> */}


              <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  Color <span style={{ color: "red" }}></span>
                </p>
                <div className={style['select-desin']}>
                  <MultiSelect
                    name="colour"
                    handleChange={(e) =>
                      setFormData({ ...formData, colour: e.value })
                    }
                    items={coloList?.map((ele) => ({
                      label: ele?.colorName,
                      value: ele?.colorId,
                    }))}
                    selectedItems={formData?.colour || colorId}
                    hint={"Select Color"}
                    containerStyle={style["selectable-button-container-style"]}
                    selectClass={style["filterDropDown"]}
                    required={true}
                  />
                </div>
              </div>



              <div className={style["input-wrapper"]}>
                {formData?.insuranceType !== "5" ? <>
                  <p className={style["label"]}>Insurance Valid Year</p>
                  <div className={style['select-desin']}>
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      adapterLocale="de"
                      sx={{
                        "& .MuiStack-root": {
                          paddingTop: "10px !important",
                        },
                      }}
                    >
                      {/* <DemoContainer components={["DatePicker", "DatePicker"]}> */}
                      <DatePicker
                        size="small"
                        openTo="year"
                        views={["year"]}
                        value={
                          formData?.insuranceType !== "5"
                            ? formData.insuranceExpYear
                              ? moment().year(formData.insuranceExpYear)
                              : null
                            : null
                        }
                        onChange={(newValue) =>
                          handleallYearChange(newValue, "insuranceExpYear")
                        }
                        required={true}
                        // className={style["custom-year-picker"]}/
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-root": {
                            height: "36px !important",
                          },
                          "& .MuiStack-root": {
                            paddingTop: "0px !important",
                          },
                        }}
                      />
                      {/* </DemoContainer> */}
                    </LocalizationProvider>
                  </div>
                </> : null}

              </div>

            </div>
          </div>
        </div>
        <div className={style["price-container"]}>
          <SecondaryHeading
            title={"Price"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />

          <div className={style['first-container']}>
            <div className={style['left-side-current']}>
              <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  Listing Price <span style={{ fontSize: "12px" }}>(Post discount)</span>
                </p>
                <div className={style['select-desin']}>
                  <InputField
                    placeholder={"Enter Price"}
                    name="listingPrice"
                    handleChange={handleChange}
                    value={formatIndianCurrency(formData?.listingPrice)}
                    length={9}
                    regexPattern={/^[\d,]+$/}
                    errorMessage={
                      formData?.listingPrice
                        ? "Price should be valid"
                        : "Please enter price"
                    }
                    requiredError={
                      !formData?.listingPrice?.length > 0 && requiredError
                    }
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  Listing Price <span style={{ fontSize: "12px" }}>(Pre discount)</span>
                </p>
                <div className={style['select-desin']}>
                  <InputField
                    placeholder={"Enter Max Selling Price"}
                    name="actual_price"
                    handleChange={handleChange}
                    value={formatIndianCurrency(
                      Math.ceil(
                        (formData?.listingPrice / (100 - formData?.discountPercent)) * 100
                      )
                    )}
                    disabled={true}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className={style['right-side-current']}>
              <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  Discount Percent (%) <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style['select-desin']}>
                  <InputField
                    placeholder={"Enter Discount %"}
                    name="discountPercent"
                    handleChange={handleChange}
                    value={formData?.discountPercent}
                    regexPattern={/^\d{1,2}(\.\d{1,2})?$/}
                    length={3}
                    errorMessage={
                      formData?.discountPercent === "" ||
                        formData?.discountPercent === null ||
                        parseFloat(formData?.discountPercent) > 99.99
                        ? "Please enter a valid discount between 0 and 99.99"
                        : ""
                    }
                    required
                    requiredError={
                      (formData?.discountPercent === null ||
                        formData?.discountPercent === "" ||
                        parseFloat(formData?.discountPercent) > 99.99) &&
                      requiredError
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style["Other-Information"]}>
          <SecondaryHeading
            title={"Other Information"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />
          <div className={style['first-container']}>
            <div className={style['left-side-current']}>
              {/* <div className={style['input-wrapper']}>
                <p className={style["label"]}>
                  Fuel Type <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style['select-desin']}>
                  <SelectableButton
                    trailingIcon={R.ic_arrow_down}
                    containerStyle={style["selectable-button-container-style"]}
                    name="fuelType"
                    handleChange={(e) => {
                      setFormData({ ...formData, fuelType: e.target.value });
                    }}
                    items={[
                      { label: "Petrol", value: 1 },
                      { label: "Deisel", value: 2 },
                      { label: "Electric", value: 3 },
                      { label: "CNG", value: 4 },
                    ]}
                    value={formData?.fuelType}
                    hint={"Select Fuel Type"}
                    required={true}
                  />
                  <ErrorMessage
                    errorMessage={!formData?.fuelType && "Please select fuel"}
                    requiredError={!formData?.fuelType?.length > 0 && requiredError}
                  />

                </div>
              </div> */}

              {InventryMetaData === "1" && (
                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Purchaser
                  </p>
                  <div className={style['select-desin']}>
                    <MultiSelect
                      name="purchaser"
                      handleChange={(e) =>
                        setFormData({ ...formData, purchaser: e.value })
                      }
                      items={purchaser?.map((ele) => ({
                        label: ele?.name,
                        value: ele?.id,
                      }))}
                      selectedItems={formData?.purchaser}
                      hint={"Select Purchaser"}
                    />
                  </div>
                </div>
              )}


              <div className={style["input-wrapper"]}>
                <p className={style["label"]}>Outlet  <span style={{ color: "red" }}>*</span></p>
                <div className={style['select-desin']}>
                  <SelectableButton
                    trailingIcon={R.ic_arrow_down}
                    containerStyle={style["selectable-button-container-style"]}
                    name="showroomId"
                    handleChange={(e) => {
                      const data = +e.target.value;
                      setFormData({ ...formData, showroomId: +data });
                    }}

                    items={dealerAddress.filter(item => item?.store_name && item?.id).map(res => ({
                        label: res.store_name,
                        value: res?.id
                    }))}
                    value={formData?.showroomId}
                    hint={"Select Outlet"}
                    required={true}
                  />
                  <ErrorMessage
                    errorMessage={!formData?.showroomId && "Please select showroom address"}
                    requiredError={!formData?.showroomId?.length > 0 && requiredError}
                  />
                </div>

              </div>



            </div>
            <div className={style['right-side-current']}>

              {InventryMetaData === "1" && (
                <div className={style["input-wrapper"]}>
                  <p className={style["label"]}>
                    Purchaser Source
                  </p>
                  <div className={style['select-desin']}>
                    <MultiSelect
                      name="source"
                      handleChange={(e) =>
                        setFormData({ ...formData, source: e.value })
                      }
                      items={source.map((ele) => ({
                        label: ele?.name,
                        value: ele?.id,
                      }))}
                      selectedItems={formData?.source}
                      hint={"Select Purchaser Source"}
                    />
                  </div>
                </div>
              )}


            </div>
          </div>
        </div>

        <div className="tags">
          <SecondaryHeading
            title={"Tags"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />
          <div className={style['tag-first-container']}>
            <div className={style['tages-first-current']}>
              <div className={style['input-wrapper']}>
                <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.trending}
                    onChange={(e) => handleCheckbox(e, "trending")}
                  />
                  <span style={{ paddingLeft: "10px" }}>Trending</span>
                </label>
              </div>

              <div className={style["input-wrapper"]}>
                <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.newModel}
                    onChange={(e) => handleCheckbox(e, "newModel")}
                  />
                  <span style={{ paddingLeft: "10px" }}>New Model</span>
                </label>

              </div>

              <div className={style["input-wrapper"]}>
                <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.discounted}
                    onChange={(e) => handleCheckbox(e, "discounted")}
                  />
                  <span style={{ paddingLeft: "10px" }}>Discounted</span>
                </label>

              </div>

              <div className={style['input-wrapper']}>
                <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.newStock}
                    onChange={(e) => handleCheckbox(e, "newStock")}
                  />
                  <span style={{ paddingLeft: "10px" }}>New Stock</span>
                </label>
              </div>
            </div>
            <div className={style['tag-second-current']}>




              {/* <div className={style["input-wrapper"]}>
                <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.isCngFitted}
                    onChange={(e) => handleCheckbox(e, "isCngFitted")}
                  />
                  <span style={{ paddingLeft: "10px" }}>Is CNG Fitted</span>
                </label>
              </div> */}

            </div>
          </div>
        </div>

        <div className="tags">
          <SecondaryHeading
            title={"List On"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />
          <div className={style['tag-first-container']}>


            <div className={style['tag-second-current']}>

              <div className={style['input-wrapper']}>
                <label>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.isClassified}
                    onChange={(e) => handleCheckbox(e, "isClassified")}
                  />
                  <span style={{ paddingLeft: "10px", fontSize: "14px"  }}>MotorPedia Website</span>
                </label>
              </div>

              <div className={style['input-wrapper']}>
                <label className={style['boxex']}>
                  <input
                    className={style["input-checkbox"]}
                    type="checkbox"
                    checked={formData.isWebsite}
                    onChange={(e) => handleCheckbox(e, "isWebsite")}
                  />
                  <span style={{ paddingLeft: "10px", fontSize: "14px"  }}>My Website</span>
                </label>
              </div>


            </div>
          </div>
        </div>

        <div className={style['marketing-data']}>
          <SecondaryHeading
            title={"Marketing Data"}
            fontSize={"1.5rem"}
            fontWeight={500}
          />
          <div className={style['first-container']}>
            <div className={style['left-side-current']}>
              <div className={style['input-wrapper']}>
                <p className={style["label"]}>Car Description</p>
                <textarea
                  placeholder="car Description"
                  name="carDescription"
                  onChange={handleChange}
                  value={formData?.carDescription}
                  className={style["textarea"]}
                  required={true}
                />

              </div>

            </div>
            <div className={style['right-side-current']}>
              <div className={style["input-wrapper"]}>
                <p className={style["label"]}>Meta Tag</p>
                <textarea
                  placeholder="Meta Tag"
                  name="metaTag"
                  onChange={handleChange}
                  value={formData?.metaTag}
                  className={style["textarea"]}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={style['action-wrapper']} style={{ marginBottom: "100px" }}>
          <PrimaryButton
            name={"Submit"}
            fontSize={"1rem"}
            containerStyle={style["primary-button-container-style"]}
            onClick={editCar}
          />
        </div>
      </div>
    </div>
  );
}
