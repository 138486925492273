import { SAVE_USERS, GET_USERS } from "../types";


const initalstate = {
    userList: [],
    userData: {
        userDetails: {}
    }
}

export default function (state = initalstate, action) {
    const { type, payload } = action;
    switch (type) {
        case SAVE_USERS:
            return {
                ...state,
                userData: { ...state?.userData, userDetails: payload }
            }
        case GET_USERS:
            return {
                ...state, userList: payload.data
            }

        default:
            return state;
    }
}