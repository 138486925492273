import { GET_BLOGS, SAVE_BLOGS } from "../types";
import { blogs } from "../../api/blogs";

export const fetchDealerBlogs = (param) => (dispatch) => {
  return blogs.getDealerBlogs(param).then(
    (response) => {
      dispatch({
        type: GET_BLOGS,
        payload: {
          data: response?.data,
          currentPage : response?.currentPage,
          hasNexPage : response?.hasNextPage,
          totalCount : response?.totalCount || 0,
          totalPageCount : response?.totalPageCount
        },
      });  
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};


export const saveBlogs = (payload) => (dispatch) => {
  return blogs.saveDealerBlogs(payload).then(
    (response) => {
      dispatch({
        type: SAVE_BLOGS,
        payload: {
          data: response?.data,
        },
      });  
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

