import style from "./style.module.scss";

export const ToggleSwitch = ({ enabled, onChange, colors, className }) => {
  const {
    enabledColor = "#007abe",
    disabledColor = "#d9d9d9",
    switchColor = "#FFFFFF",
  } = colors || {};

  const sliderStyle = {
    backgroundColor: enabled ? enabledColor : disabledColor,
  };
  
  const switchStyle = {
    backgroundColor: switchColor,
    alignSelf: enabled ? "flex-end" : "flex-start",
    transition: "all 0.3s ease",
  };

  return (
    <div className={style["container"]}>
      <div
        style={sliderStyle}
        className={[style["slider-base"], className].join(" ")}
        onClick={() => onChange(!enabled)} // Ensure correct toggle behavior
      >
        <div style={switchStyle} className={style["switch"]}></div>
      </div>
    </div>
  );
};
