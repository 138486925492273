import axios from "axios";
export const API_URL = process.env.REACT_APP_API_URL;
export const NEST_API_URL = process.env.REACT_APP_NEST_API_URL;
export const INVENTRY_SERVICES_URL =
  process.env.REACT_APP_INVENTRY_SERVICES_URL;
export const DEALER_URL = process.env.REACT_APP_DEALER_URL;
export const DEALER_APP_URL = process.env.REACT_APP_SERVICE_HISTORY_URL;
export const SERVICE_HISTORY_URL = process.env.REACT_APP_SERVICE_HISTORY_URL;
export const TRUE_PRICE = process.env.REACT_APP_TRUE_PRICE;
export const DC_APIKEY = process.env.REACT_APP_DC_APIKEY;
export const ADMIN_APIKEY = process.env.REACT_APP_ADMIN_APIKEY;
export const DEFAULT_DEALER_ID = process.env.REACT_APP_DEFAULT_DEALER_ID;


// Create an Axios instance
const axiosInstance = axios.create({
  apiUrl: API_URL,
});

// Add a request interceptor

axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token from local storage or any other storage mechanism
    const token = localStorage.getItem("accessToken");

    if (token) {
      // Add the token to the headers
      config.headers.authorization = `bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

export default axiosInstance;
