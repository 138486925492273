import { Box, Typography, Pagination, Stack } from "@mui/material";
import style from "../faqs-management/faqsList.module.scss";
import SearchBar from "../../components/search-bar/SearchBar";
import SelectableButton from "../../components/buttons/SelectableButton";
import { R } from "../../constants/resources";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import UserTable from "../../components/tables/userTable";
import { useDispatch, useSelector } from "react-redux";
import { getDealerusers } from "../../store/users/userAction";

export const DealerRoles = () => {
    const dispatch = useDispatch();
    const { data, totalCount, totalPageCount } = useSelector((state) => state?.userReducers?.userList || []);

    const navigate = useNavigate();
    const [filter, setFilter] = useState("");
    const [key, setKey] = useState(0);
    const [searchText, setSearchtext] = useState("");
    const [filterdData, setFilterData] = useState([]);


    const [pagination, setPagination] = useState({
        pageIndex: 1,
        pageSize: 0,
    });

    const applyFilter = async () => {
        await dispatch(getDealerusers({ isActive: filter }))
    };
    const resetFilter = async () => {
        setFilter("");
        setKey(prev => prev + 1);
        await dispatch(getDealerusers());
    };

    useEffect(() => {
        dispatch(getDealerusers({ page: pagination.pageIndex }));
    }, [dispatch, pagination])


    const handleSearch = () => {
        let filterd = data;

        if (searchText) {
            filterd = filterd.filter((item) => item?.firstname?.toLowerCase().includes(searchText.toLowerCase())
            )
        }
        setFilterData(filterd);
    }

    useEffect(() => {
        handleSearch();
    }, [searchText, data]);




    return (
        <div style={{ padding: "10px" }}>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "15px 0",
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: 600, px: 2 }}
                >
                    User List ({totalCount})
                </Typography>
            </Box>
            <div className={style["filters-container"]}>
                <div className={style["filters-wrapper"]}>
                    <SearchBar
                        placeholder={"Search Name"}
                        handleChange={(e) => {
                            setSearchtext(e.target.value);
                        }}
                    />
                    <SelectableButton
                        key={key}
                        trailingIcon={R.ic_arrow_down}
                        containerStyle={style["selectable-button-container-style"]}
                        handleChange={(e) => {
                            setFilter(e.target.value);
                        }}
                        items={[
                            { label: "Active", value: "1" },
                            { label: "Inactive", value: "0" }
                        ]}
                        hint={"status"}
                        selectedItems={filter}
                    />

                    <PrimaryButton
                        containerStyle={style["add-new-car-button-container-style"]}
                        name={" Filter"}
                        fontSize={"1rem"}
                        onClick={applyFilter}
                    />

                    <PrimaryButton
                        containerStyle={style["add-new-car-button-container-style"]}
                        name={"Reset"}
                        fontSize={"1rem"}
                        onClick={() => resetFilter()}
                    />

                </div>
                <div className={style["action-buttons-wrapper"]}>
                    <PrimaryButton
                        containerStyle={style["add-blogs-button"]}
                        leadingIcon={R.ic_add_white}
                        name={"Add New User"}
                        fontSize={"1rem"}
                        linkTo={"/create-user"}
                        onClick={() => navigate("/create-user")}
                    />
                </div>
            </div>
            <UserTable
                data={filterdData}
            />

            <Stack
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
                marginTop={"20px"}
            >
                <Pagination
                    count={totalPageCount}
                    page={pagination.pageIndex}
                    onChange={(event, page) => {
                        setPagination((prev) => ({
                            ...prev,
                            pageIndex: page
                        }));
                    }}
                    variant="outlined"
                    shape="rounded"
                />
            </Stack>

        </div>
    )
}