
import { Formik, Form } from "formik";
import {
    Grid,
    Box,
    Button,
    Typography,
    TextField,
    Link,
    IconButton,
} from "@mui/material";
import BackButton from "../../../components/buttons/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./user.module.scss";
import InputField from "../../../components/input-field/InputField";
import SelectableButton from "../../../components/buttons/SelectableButton";
import { R } from "../../../constants/resources";
import usersApi from "../../../api/usersApi";
import { useEffect, useState } from "react";
import { saveDealerUsers } from "../../../store/users/userAction";
import { useDispatch, useSelector } from "react-redux";
import { fetchDealerAddress } from "../../../store/car/carAction";
import validationSchema from "../../../components/validation/validationSchema";
import { RiErrorWarningLine } from "react-icons/ri";

function UserManagement() {

    const { dealerAddress } = useSelector((state) => state?.car || []);

    const navigate = useNavigate();
    const location = useLocation();
    const edited = location?.state?.data || "";
    const [parentRole, setParentRole] = useState([]);
    const [dealerRole, setDealerRole] = useState([]);
    const dispatch = useDispatch();
    const dealerId = localStorage.getItem("dealerId");

    const initalvalues = {
        id: edited?.id || null,
        firstname: edited?.firstname || "",
        lastname: edited?.lastname || "",
        email: edited?.email || "",
        phone: edited?.phone || "",
        status: edited?.status || "",
        password: "",
        dealer_id: dealerId,
        roleId: edited?.roleId || "",
        user_role_id: edited?.user_role_id || "",
        store_id: edited?.store_id || "",
        emp_id: edited?.emp_id || ""
    }

    const addUser = (values) => {
        const payload = Object.fromEntries(Object.entries(values).filter(([Key, value]) => value !== undefined && value !== null && value !== ""));
        dispatch(saveDealerUsers(payload)).then(res => {
            navigate("/user-list")
        }).catch((error) => {
            console.log(error);
        })
    }

    const getparentRole = async () => {
        try {
            const res = await usersApi.getDealerParentRole();
            setParentRole(res?.data)
        } catch (error) {
            console.log("error", error?.message);
        }
    }

    const getdealerRole = async () => {
        try {
            const res = await usersApi.getDealerRole();
            setDealerRole(res?.data);
        } catch (error) {
            console.log(error?.message);
        }
    }

    useEffect(() => {
        getdealerRole();
        getparentRole();
    }, [])

    useEffect(() => {
        dispatch(fetchDealerAddress({ dealer_id: dealerId }))
    }, [dispatch])

    return (
        <div className={style['heading']}>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    alignItems: "center",
                    padding: "15px ",
                }}
            >
                <BackButton
                    onClick={() => {
                        navigate("/user-list");
                    }}
                />
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: 600, px: 2 }}
                >
                    {edited?.id ? "Edit Users" : "Create Users"}
                </Typography>
            </Box>

            <div style={{ marginTop: "10px" }}>
                <Formik
                    initialValues={initalvalues}
                    validationSchema={validationSchema.userManagement(edited?.id || "")}
                    enableReinitialize
                    onSubmit={addUser}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting,

                    }) => (
                        <Form>
                            <div className={style['header-wrapper']}>
                                <div>
                                    <div className={style["first-heading"]}>
                                        <div className={style['select-wraapper']}>
                                            <label>Employee Code</label>
                                            <div className={style['sel-wrapper']}>
                                                <InputField
                                                    type={"text"}
                                                    name="emp_id"
                                                    handleChange={handleChange}
                                                    value={values.emp_id}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className={style['select-wraapper']}>
                                            <label>Store <span className={style['errors']}>*</span></label>
                                            <div className={style['sel-wrapper']}>
                                                <SelectableButton
                                                    trailingIcon={R.ic_arrow_down}
                                                    containerStyle={style["selectable-button-container-style"]}
                                                    handleChange={(e) => {
                                                        setFieldValue("store_id", e.target.value);
                                                    }}
                                                    items={dealerAddress?.filter(item => item?.id && item?.store_name)?.map(res => ({
                                                        label: res?.store_name,
                                                        value: res?.id
                                                    }))}
                                                    hint={"select store"}
                                                    value={values?.store_id}
                                                />
                                                {errors?.store_id && <span className={style['errors']}><RiErrorWarningLine className={style['blank-error']} />{errors.store_id}</span>}
                                            </div>
                                        </div>


                                    </div>
                                    <div className={style["first-heading"]}>
                                        <div className={style['select-wraapper']}>
                                            <label>First Name<span className={style['errors']}>*</span></label>
                                            <div className={style['sel-wrapper']}>
                                                <InputField
                                                    type={"text"}
                                                    name="firstname"
                                                    handleChange={handleChange}
                                                    value={values.firstname}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                />
                                                {errors?.firstname && <span className={style['errors']}><RiErrorWarningLine className={style['blank-error']} />{errors.firstname}</span>}
                                            </div>
                                        </div>
                                        <div className={style['select-wraapper']}>
                                            <label>Last Name</label>
                                            <div className={style['sel-wrapper']}>
                                                <InputField
                                                    type={"text"}
                                                    name="lastname"
                                                    handleChange={handleChange}
                                                    value={values.lastname}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>


                                    </div>
                                    <div className={style["first-heading"]}>
                                        <div className={style['select-wraapper']}>
                                            <label>Email<span className={style['errors']}>*</span></label>
                                            <div className={style['sel-wrapper']}>
                                                <InputField
                                                    type={"text"}
                                                    name="email"
                                                    handleChange={handleChange}
                                                    value={values.email}
                                                    autoComplete="off"
                                                    onBlur={handleBlur}
                                                />
                                                {errors?.email && <span className={style['errors']}><RiErrorWarningLine className={style['blank-error']} />{errors.email}</span>}
                                            </div>
                                        </div>
                                        <div className={style['select-wraapper']}>
                                            <label>Mobile <span className={style['errors']}>*</span></label>
                                            <div className={style['sel-wrapper']}>
                                                <InputField
                                                    name="phone"
                                                    handleChange={handleChange}
                                                    value={values?.phone}
                                                    length={10}
                                                    regexPattern={/^[\d,]+$/}
                                                    autoComplete="off"
                                                />
                                                {errors?.phone && <span className={style['errors']}><RiErrorWarningLine className={style['blank-error']} />{errors.phone}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style["first-heading"]}>
                                        <div className={style['select-wraapper']}>
                                            <label>Password<span className={style['errors']}>*</span></label>
                                            <div className={style['sel-wrapper']}>
                                                <InputField
                                                    type={"text"}
                                                    name="password"
                                                    handleChange={handleChange}
                                                    value={values.password}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                />
                                                {errors?.password && <span className={style['errors']}><RiErrorWarningLine className={style['blank-error']} />{errors.password}</span>}
                                            </div>
                                        </div>
                                        <div className={style['select-wraapper']}>
                                            <label>Status</label>
                                            <div className={style['sel-wrapper']}>
                                                <SelectableButton
                                                    trailingIcon={R.ic_arrow_down}
                                                    containerStyle={style["selectable-button-container-style"]}
                                                    handleChange={(e) => {
                                                        setFieldValue("status", e.target.value);
                                                    }}
                                                    items={[
                                                        { label: "Active", value: "1" },
                                                        { label: "Inactive", value: "0" }
                                                    ]}
                                                    hint={"status"}
                                                    value={values?.status}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={style["first-heading"]}>
                                        <div className={style['select-wraapper']}>
                                            <label>Parent Role<span className={style['errors']}>*</span></label>
                                            <div className={style['sel-wrapper']}>
                                                <SelectableButton
                                                    trailingIcon={R.ic_arrow_down}
                                                    containerStyle={style["selectable-button-container-style"]}
                                                    handleChange={(e) => {
                                                        setFieldValue("roleId", +e.target.value);
                                                    }}
                                                    items={parentRole?.map(res => ({
                                                        label: res?.name,
                                                        value: res?.id
                                                    }))}
                                                    value={values?.roleId}
                                                    hint={"select parent role"}
                                                />
                                                {errors?.roleId && <span className={style['errors']}><RiErrorWarningLine className={style['blank-error']} />{errors.roleId}</span>}
                                            </div>
                                        </div>
                                        <div className={style['select-wraapper']}>
                                            <label>Role <span className={style['errors']}>*</span> </label>
                                            <div className={style['sel-wrapper']}>
                                                <SelectableButton
                                                    trailingIcon={R.ic_arrow_down}
                                                    containerStyle={style["selectable-button-container-style"]}
                                                    handleChange={(e) => {
                                                        setFieldValue("user_role_id", +e.target.value);
                                                    }}
                                                    items={dealerRole?.map(res => ({
                                                        label: res?.role_name,
                                                        value: res?.id
                                                    }))}
                                                    value={values?.user_role_id}
                                                    hint={"select role"}
                                                />
                                                {errors?.user_role_id && <span className={style['errors']}><RiErrorWarningLine className={style['blank-error']} />{errors.user_role_id}</span>}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className={style['action-wrapper']}>
                                    <div>
                                        <Button
                                            sx={{
                                                backgroundColor: "#121b5b",
                                                color: "#fff",
                                                "&:hover": {
                                                    backgroundColor: "#121b5b",
                                                    color: "#fff",
                                                },
                                            }}
                                            type="button" // isko button rakhein kyunki ye submit nahi hai
                                            variant="contained"
                                            color="primary"
                                            className={style["form-published"]}
                                            onClick={() => navigate("/user-list")} // Navigate back
                                        >
                                            Back
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            sx={{
                                                backgroundColor: "#121b5b",
                                                color: "#fff",
                                                "&:hover": {
                                                    backgroundColor: "#121b5b",
                                                    color: "#fff",
                                                },
                                            }}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={style["form-published"]}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>

                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default UserManagement;