import * as Yup from 'yup';

class ValidationSchema {
    soldManagement(leadOpenPnl, purchaseSeen, soldValue) {
        const isValidationDisabled = leadOpenPnl === "1" || purchaseSeen === "1" || soldValue === "2";

        return Yup.object({
            sold_date: isValidationDisabled
                ? Yup.date().nullable()
                : Yup.date().required('Sold date is required'),
            sold_amount: isValidationDisabled
                ? Yup.string().nullable()
                : Yup.string().required('Sold amount is required'),
            sold_to: isValidationDisabled
                ? Yup.string().nullable()
                : Yup.string().required('Sold to is required'),
        });
    }

    userManagement(id) {
        return Yup.object({
            firstname: Yup.string()
                .required("First name is required"),
            email: Yup.string()
                .email("Invalid email format")
                .required("Email is required"),
            phone: Yup.number()
                .required("Mobile number is required"),
            password: id
                ? Yup.string() 
                : Yup.string()
                    .required("Password is required")
                    .min(6, "Password must be at least 6 characters"),
            roleId: Yup.number()
                .required("Parent role is required"),
            user_role_id: Yup.number()
                .required("User role is required"),
            store_id: Yup.number()
                .required("store name is required"),
        });
    }

}

export default new ValidationSchema();
