import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { buyerApi } from "../../../api/buyerLeadApi";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  MenuItem,
  Grid,
} from "@mui/material";

const Step0 = ({ setCurrentStep, id }) => {
  const [dealers, setDealers] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [buyerLeadStatus, setbuyerLeadStatus] = useState([]);

  const [preferences, setPreferences] = useState({
    leadId: +id.path,
    comment: "",
    statusId: "",
    followDate: null,
    name: "",
    email: "",
  });

  const [temPreferences, setTempPreferences] = useState({
    leadId: +id.path,
    comment: "",
    statusId: "",
    followDate: null,
    name: "",
    email: "",
    mobile: "",
  });

  const navigate = useNavigate();

  const buyerLeadStstus = async () => {
    try {
      const res = await buyerApi.getBuyerLeadStatus();
      if (res) {
        setbuyerLeadStatus(res.data);
      }
    } catch (err) {
      console.error("Failed to fetch customer info:", err);
    }
  };

  const handleStatusChange = (event) => {
    const statusId = event.target.value;
    setPreferences((prev) => ({ ...prev, statusId }));
    setTempPreferences((prev) => ({ ...prev, statusId }));
  };

  const customerStatus = async (params = {}) => {
    try {
      const res = await buyerApi.getCustomerInfo({ leadId: id.path });

      setCustomerList(res?.data);

      const followDate = res?.data[0]?.followDate
        ? new Date(res.data[0].followDate)
        : null;
      const statusId = res?.data[0]?.statusId || null;
      const name = res?.data[0]?.name || "";
      const email = res?.data[0]?.email || "";
      const mobile = res?.data[0]?.mobile || "";

      setPreferences((prev) => ({
        ...prev,
        followDate,
        statusId,
        name,
        email,
        mobile,
      }));

      setTempPreferences((prev) => ({ ...prev, followDate }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleBack = () => {
    navigate("/buyer-lead-management");
  };

  const handleSave = async () => {

    const isAllFieldsEmpty = Object.entries(temPreferences)
      .filter(([key]) => key !== "leadId")
      .every(([_, value]) => value === "" || value === null);


    if (isAllFieldsEmpty) {
      toast.error("Please fill at least one field!");
      return;
    }

    if (isAllFieldsEmpty) {
      toast.error("Please fill at least one field!");
      return;
    }

    const today = moment().startOf("day");
    if ([2, 1, 3, 4, 5, 6].includes(+temPreferences.statusId)) {
      if (!temPreferences.followDate) {
        toast.error("Follow-up date Required.");
        return;
      }

      const followDateMoment = moment(temPreferences.followDate, "YYYY-MM-DD");
      if (followDateMoment.isBefore(today)) {
        toast.error("Follow-up date cannot be in the past.");
        return;
      }
    }

    const cleanedPreferences = Object.fromEntries(
      Object.entries(temPreferences).filter(
        ([_, value]) => value !== null && value !== ""
      )
    );


    try {
      const response = await buyerApi.addUpdateCustomerInfo(cleanedPreferences);
      toast.success("Data saved successfully!");
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const handleSaveNext = async () => {
  
    const isAllFieldsEmpty = Object.entries(preferences)
      .filter(([key]) => key !== "leadId")
      .every(([_, value]) => value === "" || value === null);

   
  
    if (isAllFieldsEmpty) {
      toast.error("Please fill at least one field!");
      return;
    }
  
  
  
    const today = moment().startOf("day");
    if ([2, 1, 4, 5, 6].includes(+preferences.statusId)) {
      if (!preferences.followDate) {
        toast.error("Follow-up date is required.");
        return;
      }
  
      const followDateMoment = moment(preferences.followDate, "YYYY-MM-DD");
      if (followDateMoment.isBefore(today)) {
        toast.error("Follow-up date cannot be in the past.");
        return;
      }
    }
  
    const cleanedPreferences = Object.fromEntries(
      Object.entries(preferences).filter(([_, value]) => value !== null && value !== "")
    );

  
    try {
      const result = await buyerApi.addUpdateCustomerInfo(cleanedPreferences);
      toast.success("Data saved successfully!");
      setCurrentStep(1);
    } catch (error) {
      toast.error(error.response?.data?.error || "An error occurred.");
    }
  };
  

  const handleDateChange = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    setPreferences((prevDetails) => ({
      ...prevDetails,
      followDate: formattedDate,
    }));

    setTempPreferences((prevDetails) => ({
      ...prevDetails,
      followDate: formattedDate,
    }));
  };

  useEffect(() => {
    // customer();
    customerStatus();
  }, []);

  useEffect(() => {
    buyerLeadStstus();
  }, []);

  return (
    <Container className={style["container"]}>
      <Box className={style["mid-container"]}>
        <Box
          sx={{ marginTop: "10px", display: "flex" }}
          className={style["status-section"]}
        >
          <Typography
            style={{ marginRight: "0px !important" }}
            variant="subtitle1"
            className={style["label"]}
          >
            Mobile:
          </Typography>

          <Typography
            sx={{ marginRight: "30px", marginBottom: "10px", color: "gray" }}
            variant="h6"
          >
            {preferences?.mobile || ""}
          </Typography>
        </Box>

        <Box
          sx={{ marginTop: "10px", display: "flex" }}
          className={style["status-section"]}
        >
          <Typography variant="subtitle1" className={style["label"]}>
            Name:
          </Typography>

          <TextField
            sx={{ width: "400px" }}
            variant="outlined"
            label="Name"
            value={preferences?.name || ""}
            onChange={(e) => {
              const value = e.target.value;
              setPreferences((prev) => ({ ...prev, name: value }));
              setTempPreferences((prev) => ({ ...prev, name: value }));
            }}
            className={style["dealer-info-text"]}
          />
        </Box>
        <Box
          sx={{ marginTop: "10px", display: "flex" }}
          className={style["status-section"]}
        >
          <Typography variant="subtitle1" className={style["label"]}>
            Email:
          </Typography>

          <TextField
            sx={{ width: "400px" }}
            variant="outlined"
            label="Email"
            value={preferences?.email || ""}
            onChange={(e) => {
              const EmailValue = e.target.value;
              setPreferences((prev) => ({ ...prev, email: EmailValue }));
              setTempPreferences((prev) => ({ ...prev, email: EmailValue }));
            }}
            className={style["dealer-info-text"]}
          />
        </Box>
        <Box
          sx={{ marginTop: "10px", display: "flex" }}
          className={style["status-section"]}
        >
          <Typography variant="subtitle1" className={style["label"]}>
            Select Status:
          </Typography>
          <TextField
            sx={{ width: "400px" }}
            select
            value={preferences.statusId}
            onChange={handleStatusChange}
            variant="outlined"
            className={style["dropdown"]}
          >
            {buyerLeadStatus.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.statusName}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box
          sx={{ marginTop: "10px", display: "flex" }}
          className={style["follow-date-section"]}
        >
          <Typography variant="subtitle1" className={style["label"]}>
            FollowUp Date:
          </Typography>
          <Box className={style["date-picker-wrapper"]}>
            <DatePicker
              selected={
                preferences.followDate ||
                (customerList?.lastEnquiryDate
                  ? new Date(customerList.lastEnquiryDate)
                  : null)
              }
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              minDate={new Date()} // Restricts selection to today or future dates
              customInput={
                <TextField
                  sx={{ width: "400px" }}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <FaCalendarAlt className={style["calendar-icon"]} />
                    ),
                  }}
                />
              }
            />
          </Box>
        </Box>
        <Box
          sx={{ marginTop: "10px", display: "flex" }}
          className={style["comment-section"]}
        >
          <Typography variant="subtitle1" className={style["label"]}>
            Last Comment:
          </Typography>
          <TextField
            sx={{ width: "400px" }}
            value={preferences.comment}
            onChange={(e) => {
              const value = e.target.value;
              setPreferences((prev) => ({ ...prev, comment: value }));
              setTempPreferences((prev) => ({ ...prev, comment: value }));
            }}
            placeholder="Enter your last comment"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            className={style["textarea"]}
          />
        </Box>
        <Box className={style["save-button-section"]}>
          <Button
            onClick={handleBack}
            variant="contained"
            className={style["save-button"]}
            style={{ backgroundColor: "#121b5b" }}
          >
            Back
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            className={style["save-button"]}
            style={{ backgroundColor: "#121b5b" }}
          >
            Save
          </Button>
          <Button
            onClick={handleSaveNext}
            variant="contained"
            className={style["save-button"]}
            style={{ backgroundColor: "#121b5b" }}
          >
            Save & Next
          </Button>
        </Box>
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default Step0;
