import { GET_USERS, SAVE_USERS } from "../types";
import usersApi from "../../api/usersApi";


export const getDealerusers = (param) => (dispatch) => {
  return usersApi.getUsers(param).then(
    (response) => {
      dispatch({
        type: GET_USERS,
        payload: {
          data: response?.data,
          currentPage : response?.currentPage,
          hasNexPage : response?.hasNextPage,
          totalCount : response?.totalCount || 0,
          totalPageCount : response?.totalPageCount
        },
      });  
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};


export const saveDealerUsers = (payload) => (dispatch) => {
    return usersApi.saveUsers(payload).then(
      (response) => {
        dispatch({
          type: SAVE_USERS,
          payload: {
            data: response?.data,
          },
        });
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  };
  