import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import style from "./multiSelect.module.scss";

const MultiSelect = ({
  items,
  selectedItems,
  handleChange,
  containerStyle,
  name,
  hint,
  isMulti,
  isSearchable,
  selectClass,
  value,
  isClearable,
  showCrossIcon,
  minWidth = "200px",
  isDisabled, // Accept isDisabled as a prop
}) => {
  const options = items?.map((item) => ({
    label: item.label,
    value: item.value,
  }));

  const selectedOptions =
    selectedItems && Array.isArray(selectedItems) && selectedItems.length > 0
      ? selectedItems?.map((item) => ({
          label: item.label,
          value: item.value,
        }))
      : items?.filter((item) => item.value == selectedItems);

  const handleChangeMultiSelect = (selectedOptions) => {
    if (!isDisabled) { // Prevent selection change when disabled
      handleChange(selectedOptions || []);
    }
  };

  return (
    <div className={containerStyle}>
      <Select
        isMulti={isMulti}
        className={style["select-desin"]}
        name={name}
        options={options}
        value={selectedOptions}
        onChange={handleChangeMultiSelect}
        classNamePrefix="select"
        placeholder={hint}
        isSearchable={isSearchable}
        isClearable={showCrossIcon !== false}
        isDisabled={isDisabled} // Apply disabled state
        styles={{
          control: (base) => ({
            ...base,
            padding: "2px",
            borderRadius: "5px",
            minWidth: minWidth,
            height: "40px",
            backgroundColor: isDisabled ? "#f0f0f0" : base.backgroundColor, // Grey out when disabled
            cursor: isDisabled ? "not-allowed" : "default",
          }),
        }}
      />
    </div>
  );
};

MultiSelect.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  containerStyle: PropTypes.string,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  showCrossIcon: PropTypes.bool,
  minWidth: PropTypes.string,
  isDisabled: PropTypes.bool, // Add isDisabled prop validation
};

export default MultiSelect;
